<template>
    <div>
        <div>
            <chat :messages="model.messages" v-if="model.messages.length > 0"></chat>
            <p class="font-thin text-center text-gray-700" v-else>
                {{ $t('inbox.requests.show.chat.empty') }}
            </p>
        </div>
        <div class="p-4 bg-gray-300" v-if="quickAnswers && quickAnswers.length > 0">
            <a href="#" class="inline-block px-2 py-1 mr-2 text-sm text-white bg-gray-700 rounded-full" v-for="quickAnswer in quickAnswers" :key="quickAnswer.id" @click.prevent="setQuickAnswer(quickAnswer)">{{ quickAnswer.title }}</a>
        </div>
        <div class="p-4 border-t border-gray-200 bg-gray-50">
            <form @submit.prevent="addMessage">
                <div class="flex mb-2">
                    <div class="w-24 h-24 mr-2 bg-center bg-cover border border-grey-200" :style="{'background-image': 'url('+image.source+')'}" v-for="image in images"></div>
                </div>
                <v-spa-textarea id="chat-new-message-textarea" :form="form" input-name="message"></v-spa-textarea>
                <div class="flex justify-end">
                    <div class="relative w-12">
                        <div class="absolute opacity-25 cursor-pointer pin-t pin-r hover:opacity-75" @click.prevent="displayAddMenu = ! displayAddMenu">
                            <img class="w-6 h-6" src="/icons/small/chat/link.svg">
                        </div>
                        <div class="absolute flex bg-white shadow bottom-full center-x" v-if="displayAddMenu">
                            <label class="relative flex items-center justify-center w-16 h-16 border cursor-pointer border-grey-200">
                                <img class="w-6 h-6" src="/icons/small/chat/photo.svg">
                                <input type="file" ref="imageUploader" class="hidden" id="chat-new-message-images-input" @change.prevent="handleImageUpload"/>
                            </label>
                            <div class="relative flex items-center justify-center w-16 h-16 border cursor-pointer border-grey-200" @click.prevent="addVideo">
                                <img class="w-6 h-6" src="/icons/small/chat/video.svg">
                            </div>
                            <div class="relative flex items-center justify-center w-16 h-16 border cursor-pointer border-grey-200" @click.prevent="addFile">
                                <img class="w-6 h-6" src="/icons/small/chat/file.svg">
                            </div>
                        </div>
                    </div>
                    <div class="relative w-12">
                        <v-spa-emoji-picker @emoji-added="addEmoji"></v-spa-emoji-picker>
                    </div>
                    <v-spa-loading-button :loading="form.is_loading">{{ $t('inbox.requests.show.chat.message.button') }}</v-spa-loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

    import Form from '~spa/classes/Form';
    import ChatVue from '../_Partials/Chat.vue';
    import imageUploadMixin from '~spa/components/_Form/image-upload-mixin';

    export default {
        components: {
            'chat': ChatVue,
        },

        mixins: [imageUploadMixin],

        props: ['model'],

        data() {
            return {
                displayAddMenu: false,
                quickAnswers: null,
                images: [],
                videos: [],
                files: [],
                form: new Form({
                    message: ''
                }, {reset: true}),
            }
        },

        methods: {
            handleImageUpload()
            {
                if ( this.loading ) {
                    return;
                }

                let files = this.$refs.imageUploader.files;

                this.getBase64ForImage(files[0])
                    .then((image) => {
                        this.images.push({source: image});
                        this.successfulUpload();
                    });
            },

            successfulUpload()
            {
                document.getElementById('chat-new-message-images-input').value = null;
                this.displayAddMenu = false;
            },

            addEmoji(emoji) {
                let value = this.insertAtCaret('chat-new-message-textarea', emoji);
                if ( value ) {
                    this.form.message = value;
                } else {
                    this.form.message += emoji;
                }
            },

            addImage()
            {
                console.log('adding image');
            },

            addVideo()
            {
                console.log('adding video');
            },

            addFile()
            {
                console.log('adding file');
            },

            addMessage()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                let promises = [];

                if ( this.form.message !== '' ) {
                    promises.push( this.addMessageText() );
                }

                for ( let i = 0; i < this.images.length; i++ ) {
                    promises.push( this.addMessageImage(this.images[i].source) );
                }

                this.form.load();
                Promise.all(promises)
                    .then(() => {
                        this.resetFormData();
                        this.form.stop();
                        window.Toasteo.success( this.$t('inbox.requests.show.chat.message.sent') );
                    })
                    .catch((error) => {
                        console.error(error);
                        this.form.stop();
                        window.Toasteo.error( this.$t('inbox.requests.show.chat.message.error') );
                    });
            },

            addMessageText()
            {
                return new Promise((resolve, reject) => {
                    axios.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/requests/${this.model.id}/messages`, {message: this.form.message})
                        .then((data) => {
                            console.log(data);
                            this.model.messages.push(data.message);
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject();
                        });
                });
            },

            addMessageImage(image)
            {
                return new Promise((resolve, reject) => {
                    axios.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/requests/${this.model.id}/messages`, {image: image})
                        .then((data) => {
                            console.log(data);
                            this.model.messages.push(data.message);
                            resolve();
                        })
                        .catch((error) => {
                            console.error(error);
                            reject();
                        });
                });
            },

            resetFormData()
            {
                this.form.message = '';
                this.images = this.videos = this.files = [];
            },

            setQuickAnswer(quickAnswer)
            {
                if ( this.form.message == '' || this.form.message == quickAnswer.answer ) {
                    this.form.message = quickAnswer.answer;
                    return;
                }

                if ( window.confirm("This will replace your current message, are you sure you wish to continue?") ) { 
                    this.form.message = quickAnswer.answer;
                }
            }
        },

        created() {
            this.$store.dispatch('getQuickAnswers', {professionalId: this.$store.state.professional.id})
                .then(answers => this.quickAnswers = answers);
        }
    }
</script>
