<template>
    <v-spa-panel panel-class="">
        <div class="relative p-6">
            <div class="flex flex-wrap">
                <div class="w-full mb-4 md:w-1/4 md:mb-0">
                    <div class="mb-1">
                        <stars :value="rating.value"></stars>
                    </div>
                    <span class="block text-gray-600">{{ rating.user.name }}</span>
                    <span class="block text-sm font-thin text-gray-400">{{ rating.created_at | moment('YYYY-MM-DD HH:mm:ss').fromNow() }}</span>
                </div>
                <div class="w-full md:w-3/4">
                    <p class="mb-1 text-gray-800" v-html="rating.text"></p>
                    <p class="mb-2 text-xs font-thin text-gray-600">Source: TrustUp.be</p>
                    <div class="justify-end hidden md:flex">
                        <v-spa-loading-button @clicked="report" class-name="relative rounded px-4 py-2 text-red-700 hover:text-red-800 transition-all transition-250" :loading="reporting" v-if="! rating.is_reported">{{ $t('visibility.ratings.card.buttons.report') }}</v-spa-loading-button>
                    </div>
                </div>
            </div>
            <div class="absolute top-0 right-0 block md:hidden">
                <v-spa-loading-button @clicked="report" class-name="relative rounded px-4 py-2 text-red-700 hover:text-red-800 transition-all transition-250" :loading="reporting" v-if="! rating.is_reported">{{ $t('visibility.ratings.card.buttons.report') }}</v-spa-loading-button>
            </div>  
        </div>
        <div class="p-6 bg-gray-100 rounded-b-lg">
            <template v-if="rating.answer">
                <span class="block mb-2 text-left text-gray-700">{{ $t('visibility.ratings.card.answer_title') }}</span>
                <div class="p-4 font-thin text-left text-gray-600 bg-white">{{ rating.answer }}</div>
            </template>
            <form @submit.prevent="answer" v-else>
                <v-spa-textarea id="form_answer" :form="form" containerClassName="mb-2" :class-name="$spa.css.form.input_full_width" input-name="answer" :display-label="false" :placeholder="$t('visibility.ratings.card.textarea')"></v-spa-textarea>
                <v-spa-loading-button :loading="form.is_loading">{{ $t('visibility.ratings.card.buttons.answer') }}</v-spa-loading-button>
            </form>
        </div>
    </v-spa-panel>
</template>

<script>
    import StarsVue from '~base/components/_Partials/Stars.vue';
    import Form from '~spa/classes/Form';

    export default {
        components: {
            'stars': StarsVue,
        },
        data() {
            return {
                reporting: false,
                form: new Form({
                    answer: ''
                })
            }
        },
        props: {
            rating: {
                type: Object
            },
            hover: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            answer()
            {
                this.form.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/ratings/${this.rating.id}/answer`)
                    .then((data) => {
                        window.Toasteo.success(
                            this.$t('visibility.ratings.card.answered')
                        );
                        this.rating = data.rating;
                    });
            },

            report()
            {
                if ( this.reporting ) {
                    return;
                }

             
             this.reporting = true;
                axios.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/ratings/${this.rating.id}/report`)
                    .then((response) => {
                        window.Toasteo.success(
                            this.$t('visibility.ratings.card.reported')
                        );
                        this.rating = response.data.rating;
                        this.reporting = false;
                    });
            }
        } 
    }
</script>
