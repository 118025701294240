<template>
    <router-link class="block" :to="getLink()">
        <v-spa-panel :class="{'hover:bg-gray-200': hover, 'border-l-4 border-gray-300 border-l-blue-600 bg-gray-100': ! request.read}">
            <div class="rounded flex space-x-8 items-center w-full">
                <div class="flex-shrink-0 w-auto md:w-request-card-source">
                    <img src="/icons/trustup.svg" class="w-5 h-5" />
                </div>
                <div class="flex items-center w-full md:flex-shrink-0 md:w-request-card-user-md">
                    <div class="flex-shrink-0 hidden w-10 h-10 bg-center bg-cover rounded-full md:block" :style="{'background-image': 'url(' + request.user.avatar + ')'}" v-if="request.user"></div>
                    <div class="w-full ml-2 flex-no-overflow ">
                        <h2 class="block mb-1 text-gray-800 md:hidden">{{ request.title }}</h2>
                        <span class="block text-gray-700 truncate" v-if="request.user">{{ request.user.name }}</span>
                        <span class="block text-xs text-gray-500">{{ request.display_date | moment('YYYY-MM-DD HH:mm:ss').fromNow().capitalize() }}</span>
                    </div>
                </div>
                <div class="hidden w-full md:block flex-no-overflow ">
                    <h2 class="mb-1 text-gray-800 truncate">{{ request.title }}</h2>
                    <p class="text-sm font-thin text-gray-600 truncate">{{ request.text }}</p>
                </div>
                <!-- <div class="justify-end flex-shrink-0 hidden md:flex w-request-card-favorite">
                    <svg v-if="request.favorited" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-red-600 devfont devfont-flag"><path d="M4 21V4"></path><path d="M4 4h8.5l1 2H20v11h-6.5l-1-2H4"></path></svg>
                </div> -->
                <div class="justify-end max-w-max flex w-request-card-button">
                    <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`request-card-${request.id}`">
                        <template v-slot:default="slotProps">
                            <div class="min-h-[40px]">
                                <div class="flex items-center justify-end h-full px-4 py-2 text-gray-600 cursor-pointer hover:text-black" @click.stop.prevent="slotProps.toggle()">
                                    <i class="text-sm group-hover:block fas fa-ellipsis-h" />
                                </div>
                            </div>
                        </template>

                        <div class="relative w-auto" :container="$spa.css.submenu.container.default" slot="submenu">
                            <a href="#" @click.prevent="$router.push(getLink())" :class="$spa.css.contextmenu.link">
                                <i :class="`${$spa.css.contextmenu.icon} fas fa-eye`" />
                                {{ $t('table.actions.open', {}) }}
                            </a>
                            <a href="#" :class="$spa.css.contextmenu.link"
                               @click.prevent="openPrintablePage">
                                <i :class="`${$spa.css.contextmenu.icon} fas fa-print`" />
                                {{ $t('table.actions.print') }}
                            </a>
                        </div>
                    </v-spa-submenu-container>
                </div>
            </div>
        </v-spa-panel>
    </router-link>
</template>

<script>
    import StarsVue from '~base/components/_Partials/Stars.vue';
    export default {
        components: {
            'stars': StarsVue
        },
        props: {
            request: {
                type: Object
            },
            hover: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            getLink()
            {
                if ( this.request.source == 'custom-form' ) {
                    return {name: 'professional.inbox.form-submissions.show', params: {professional: this.$store.state.professional.id, submission: this.request.id}}
                }

                return {name: 'professional.inbox.requests.show', params: {professional: this.$store.state.professional.id, request: this.request.id}};
            },

            openPrintablePage()
            {
                let routeData = this.$router.resolve({name: 'professional.inbox.requests.print', params: {professional: this.$store.state.professional.id, request: this.request.id}});
                window.open(routeData.href, '_blank');
            },
        }
    }
</script>
