export default {

    getStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/statistics/`,
            key: 'statistics'
        });
    },

    getLatestStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/statistics/latest`,
            key: 'statistics'
        });
    },

    getTotalStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/statistics/total`,
            key: 'statistics'
        });
    },

    getWebsiteStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/website-statistics/`,
            key: 'statistics'
        });
    },

    getWebsiteLatestStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/website-statistics/latest`,
            key: 'statistics'
        });
    },

    getWebsiteTotalStatistics(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/website-statistics/total`,
            key: 'statistics'
        });
    }

}