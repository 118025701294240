<template>
    <div class="h-full">
        <div class="px-6 lg:px-12 border-b border-slate-200" :class="titleContainerClass">
            <div class="w-full flex items-center justify-between pt-2 min-h-[100px]">
                <div class="flex-grow">

                    <!-- SLOT: TITLE -->
                    <h1 class="v-spa-layout-title text-lg md:text-xl text-slate-800 font-normal" :class="titleClass">
                        <slot></slot>
                    </h1>
                </div>

                <!-- SLOT: BUTTON ON THE RIGHT OF THE TITLE-->
                <div class="flex items-center">
                    <slot name="button"></slot>

                    <!-- Used if you want to put a form submit button in the header -->
                    <portal-target name="page-header-button"></portal-target>
                </div>
            </div>

            <!-- SLOT: LINKS AT THE BOTTOM OF TITLE -->
            <div class="flex space-x-8">
                <slot name="links">
                    <!-- TODO: Find a better solution to fix the difference of height when no links are provided -->
                    <!-- Empty space to prevent a differnce of height when no links is provided -->
                    <div class="w-full" style="height: 17px"></div>
                </slot>
            </div>
        </div>

        <!-- SLOT: CONTENT OF THE PAGE -->
        <div class="px-6 lg:px-12 mt-12 lg:max-w-container h-full" :class="contentContainerClass">
            <slot name="content"></slot>
        </div>
    </div>

</template>

<script>
export default {
    name: "Page",

    props: {
        titleContainerClass: {
            type: String,
        },
        titleClass: {
            type: String,
        },
        contentContainerClass: {
            type: String,
        },
    },
}
</script>

<style scoped>

</style>
