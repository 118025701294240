<template>
    <div>
        <v-spa-layout-title>
            {{ $t('visibility.homepage.statistics.title') }}
        </v-spa-layout-title>
        <div class="flex flex-wrap -mx-2">
            <div class="w-full px-2" v-if="loadings.statistics">
                <v-spa-panel :loading="loadings.statistics"></v-spa-panel>
            </div>
            <template v-else>
                <div class="w-1/2 px-2 mb-4" v-if="statistics">
                    <v-spa-panel panel-color="bg-primary-dark">
                        <p class="text-white">
                            <span class="block text-4xl">{{ statistics.page }}</span>
                            <span class="block text-sm font-thin">{{ $t('visibility.homepage.statistics.page_views') }}</span>
                        </p>
                    </v-spa-panel>
                </div>
                <template v-if="website_statistics && website_statistics.sites">
                    <div class="w-1/2 px-2 mb-4" v-for="site in website_statistics.sites" :key="site.id">
                        <v-spa-panel panel-color="bg-primary-dark">
                            <p class="text-white">
                                <span class="block text-4xl">{{ site.statistics.sessions.data }}</span>
                                <span class="block text-sm font-thin">{{ $t('visibility.homepage.statistics.website_views', {name: site.name}) }}</span>
                            </p>
                        </v-spa-panel>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    require('chart.js');
    require('hchs-vue-charts');
    Vue.use(VueCharts);
    import mixin from '~spa/components/_Table/index-mixin.js';

    export default {
        mixins: [mixin],

        data() {
            return {
                loadings: {
                    statistics: true
                },
                statistics: null,
                website_statistics: null
            }
        },

        methods: {
            getTotalStatistics()
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('getTotalStatistics', {professionalId: this.$store.state.professional.id})
                        .then(statistics => {
                            this.statistics = statistics;
                            resolve();
                        });
                });
            },

            getWebsiteTotalStatistics()
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('getWebsiteTotalStatistics', {professionalId: this.$store.state.professional.id})
                        .then(statistics => {
                            this.website_statistics = statistics;
                            resolve();
                        });
                });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-homepage');

            Promise.all([
                this.getTotalStatistics(),
                this.getWebsiteTotalStatistics()
            ]).then(() => {
                this.loadings.statistics = false;
            });
        }
    }
</script>