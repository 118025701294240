<template>
    <div>
        <v-spa-layout
            ref="layout"
            @toggle-sidebar="handleSidebar"
            @show-sidebar="handleSidebar"
            @hide-sidebar="handleSidebar"
            :display-responsive-menu-on-desktop="false"
            responsiveSidebarClass="p-6"
            responsiveMenuIcon="/spa/close.svg"
        >
            <layout-responsive-sidebar ref="responsiveSidebar" slot="header-sidebar"></layout-responsive-sidebar>
            <layout-sidebar ref="sidebar" slot="sidebar" v-if="display.sidebar"></layout-sidebar>
            <layout-content ref="content" slot="content">
                <slot name="content"></slot>
            </layout-content>
        </v-spa-layout>

        <portal-target name="profile-visual"></portal-target>
        <portal-target name="profile-visual2"></portal-target>
    </div>
</template>

<script>

    import Sidebar from './_Layout/Sidebar';
    import ResponsiveSidebar from './_Layout/ResponsiveSidebar';
    import Content from './_Layout/Content';
    export default {
        components: {
            'layout-responsive-sidebar': ResponsiveSidebar,
            'layout-sidebar': Sidebar,
            'layout-content': Content
        },
        data() {
            return {
                display: {
                    sidebar: true
                }
            }
        },
        methods: {
            handleSidebar(status) {
                if ( ! this.$store.state.layout.sidebar ) {
                    this.display.sidebar = false;
                    return;
                }

                this.display.sidebar = status;
            }
        },
        mounted() {
            if ( ! this.$store.state.layout.sidebar ) {
                this.$bus.$emit('spa:hideSidebar')
            }
        }
    }
</script>
