<template>
    <div class="mb-4">
        <draggable class="w-full grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-4" draggable=".item" v-model="form.images" group="images" @start="drag=true" @end="drag=false">
            <div class="relative w-full aspect-video bg-center bg-no-repeat bg-cover rounded item hover:cursor-grab active:cursor-grabbing" :class="{'': index == 0, 'loading': image.loading}" :style="`background-image: url('${image.source}')`" v-for="(image, index) in form.images" :key="`image-${index}`">
                <div class="absolute top-0 right-0 flex mt-2 mr-2">
                    <div @click="rotate('left', index)" class="flex items-center justify-center w-6 h-6 mr-1 bg-white border border-gray-100 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-arrow-circle-left"><circle cx="12" cy="12" r="9" transform="rotate(-90 12 12)"></circle><path d="M16 12H8"></path><path d="M11 15l-3-3 3-3"></path></svg>
                    </div>
                    <div @click="rotate('right', index)" class="flex items-center justify-center w-6 h-6 mr-1 bg-white border border-gray-100 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-arrow-circle-right"><circle cx="12" cy="12" r="9" transform="rotate(90 12 12)"></circle><path d="M8 12h8"></path><path d="M13 9l3 3-3 3"></path></svg>
                    </div>
                    <div @click="form.images.splice(index, 1)" class="flex items-center justify-center w-6 h-6 bg-white border border-gray-100 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-x"><path d="M7 7l10 10"></path><path d="M17 7L7 17"></path></svg>
                    </div>
                </div>
                <v-spa-loader v-if="image.loading"></v-spa-loader>
            </div>
            <div class="relative h-24 rounded" v-for="index in loadingImages" :key="`loader-${index}`">
                <v-spa-loader></v-spa-loader>
            </div>

            <!-- ADD IMAGE BUTTON -->
            <label class="flex items-center justify-center row-span-2 w-full aspect-video text-gray-800 bg-gray-100 rounded cursor-pointer" for="project-images-form-input">
                <div class="flex flex-wrap justify-center p-4">
                    <i class="fal fa-image text-4xl"></i>
                    <span class="block w-full text-center text-gray-500">{{ $t('visibility.projects.form.add_image') }}</span>
                </div>
            </label>
        </draggable>
        <input type="file" ref="imageUploader" class="hidden" id="project-images-form-input" name="project-images-form-input" @change.prevent="handleImageUpload" multiple :accept="supportedFormats" />
    </div>

    <!-- <v-image-uploader id="form_images" :form="form" input-name="images" :multiple="true"></v-image-uploader> -->
</template>

<script>
    import draggable from 'vuedraggable';
    import UploadedImage from '~base/classes/UploadedImage';

    export default {
        props: ['project', 'form'],
        components: {
            draggable
        },
        data() {
            return {
                supportedFormats: null,
                loadingImages: 0
            }
        },
        methods: {
            handleImageUpload()
            {
                if ( this.loading ) {
                    return;
                }

                let images = this.$refs.imageUploader.files;
                this.loadingImages += images.length;
                for ( let i = 0; i < images.length; i++ ) {
                    let processor = new UploadedImage();
                    processor.check(images[i])
                        .then(() => processor.get(images[i]))
                        .then(data => {
                            this.loadingImages--;
                            this.form.images.push(data);
                            this.resetInput('project-images-form-input');
                        });
                }
            },

            resetInput(id)
            {
                document.getElementById(id).value = null;
            },

            getSource(image)
            {
                return new Promise((resolve, reject) => {
                    if ( image.file ) {
                        return resolve(image.source);
                    }
                    if ( image.base64 ) {
                        return resolve(image.source);
                    }

                    axios.get(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/projects/${this.project.id}/medias/${image.id}` )
                        .then((response) => {
                            console.log(response);
                            return resolve(response.data.media.base64);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        })
                });
            },

            rotate(direction, index)
            {
                let image = this.form.images[index];
                let degrees = direction == 'left' ? 270 : 90;
                console.log('Rotation starts');
                if ( ! this.form.images[index].hasOwnProperty('loading') ) {
                    this.$set(this.form.images[index], 'loading', true);
                }
                this.form.images[index].loading = true;

                this.getSource(image)
                    .then((source) => new UploadedImage().rotate(source, degrees))
                    .then((data) => {
                        console.log('Rotation done');
                        this.form.images[index].source = data;
                        this.form.images[index].base64 = data;
                        this.form.images[index].loading = false;
                    })
                    .catch((error) => this.form.images[index].loading = false);
            }
        },
        created() {
            this.supportedFormats = new UploadedImage().rules.mimes.join();
        }
    }
</script>
