export default class UploadedFile
{

    constructor({
        extensions = ['pdf', 'doc', 'docx', 'xlxs', 'xls', 'csv'],
        mimes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        size = 10000000,
    } = {}) {
        this.rules = {extensions, mimes, size};
    } 
    
    check(file)
    {
        return new Promise((resolve, reject) => {
            if ( ! this.validSize(file) || ! this.validExtension(file) ) {
                return reject();
            }
            resolve();
        });
    }

    validSize(file)
    {
        if ( file.size > this.rules.size ) {
            console.error('Size is too large.');
            return false;
        }
        return true;
    }

    validExtension(file)
    {
        let extension = file.name.split('.').pop().toLowerCase();
        let validExtension = this.rules.extensions.indexOf(extension) > -1;
        let validMime = this.rules.mimes.indexOf(file.type) > -1;

        if ( ! validExtension || ! validMime ) {
            console.error('File extension or mime is not supported.');
            return false;
        }
        return true;
    }
    
    get(file)
    {    
        return new Promise((resolve) => {
            resolve({
                file: file,
                name: file.name,
                description: '',
                is_focused: false,
            });
        });
    }
}