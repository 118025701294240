<template> 
    <div>
        <div class="px-4 py-6 border-b border-gray-100">
            <div class="flex items-center w-full mb-4" v-if="model.user.full_name">
                <div class="flex-shrink-0 w-10 h-10 mr-4 bg-gray-100 bg-center bg-no-repeat bg-cover rounded" :style="`background-image: url('${model.user.avatar}')`" v-if="model.user.avatar"></div>
                <div>
                    <span class="block mb-1 text-lg text-gray-700 break-words-gracefully">{{ model.user.full_name }}</span>
                </div>
            </div>

            <a :href="`mailto:${model.user.email}`" class="block w-full px-4 py-2 mb-4 text-left text-gray-700 bg-gray-200 rounded break-words-gracefully hover:bg-gray-300" v-if="model.user.email">
                <i class="mr-2 text-sm fas fa-envelope"></i>
                <span>{{ model.user.email }}</span>
            </a>
            
            <a :href="`tel:${model.user.phone_number}`" class="block w-full px-4 py-2 text-left text-gray-700 bg-gray-200 rounded break-words-gracefully hover:bg-gray-300" v-if="model.user.phone_number">
                <i class="mr-2 text-sm fas fa-phone"></i>
                <span>{{ model.user.phone_number }}</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['model']
    }
</script>
