export default {

    getWebsiteAlbums(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/website-albums`,
            key: 'albums'
        });
    }

}