<template>
    <v-spa-panel :title="$t('inbox.requests.show.panels.website_message.title')">
        <p class="font-thin text-gray-700">{{ model.messages[0].text }}</p>
    </v-spa-panel>
</template>

<script>
    export default {
        props: ['model']
    }
</script>

