<template>
    <v-spa-panel :title="$t('inbox.requests.show.panels.quote_informations.title')">
        <span class="block mb-1 text-gray-500" v-if="model.model.start_time">
            {{ $t('inbox.requests.show.panels.quote_informations.start_time') }}:
            <span class="ml-1 text-gray-700">{{ model.model.start_time.title }}</span>
        </span>
    </v-spa-panel>
</template>

<script>
    export default {
        props: ['model']
    }
</script>
