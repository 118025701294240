<template>
    <informations-base>
        <div class="max-w-800" slot="informations-content">
            <v-spa-panel :title="$t('visibility.informations.visuals.cover.title')">
                <div class="flex flex-wrap justify-center py-2" v-if="$store.state.professional.cover && ! edit">
                    <img :src="$store.state.professional.cover" class="mb-2" style="max-width: 800px; max-height: 200px;" @click.prevent="edit = true">
                    <p class="w-full text-sm text-center text-gray-700">{{ $t('visibility.informations.visuals.cover.edit_helper') }}</p>
                </div>
                <form @submit.prevent="update" v-else>
                    <div class="mb-4">
                        <v-spa-crop ref="cropCover" class-name="cover" :aspect-ratio="1180 / 300" :crop-size="{width: 1180, height: 300}" :size="{width: '800px', height: '400px'}">
                        </v-spa-crop>
                    </div>
                    <div class="flex items-center justify-end">
                        <a href="#" class="inline-block mr-4 text-gray-600" @click.prevent="edit = false" v-if="$store.state.professional.cover">{{ $t('visibility.informations.visuals.cover.cancel') }}</a>
                        <v-spa-loading-button :loading="loading">{{ $t('visibility.informations.visuals.cover.button') }}</v-spa-loading-button>
                    </div>
                </form>
            </v-spa-panel>
        </div>
    </informations-base>
</template>

<script>

    import Base from './Base';
    import mixin from './visual-mixin';

    export default {
        components: {
            'informations-base': Base
        },

        mixins: [mixin],

        methods: {
            update()
            {
                this.updateVisual('cover', this.$refs.cropCover.getCroppedResult());
            }
        },

        created() {
            //
        }
    }
</script>