<template>
    <a href="#" @click.prevent="toggle" class="w-full flex items-center text-sm text-gray-800 py-2 group">
        <span class="flex flex-no-shrink justify-center items-center h-4 w-4 rounded-full border-2 group-hover:border-primary mr-2 border-gray-200" :class="{ 'border-primary': isSelected, 'border-gray-200': ! isSelected }">
            <template v-if="isSelected">
                <span class="h-2 w-2 rounded-full bg-primary"></span>
            </template>
        </span>
        <div class="h-10 w-10 bg-cover mr-4" :style="{'background-image': 'url('+facebookPage.image+')'}"></div>
        <div class="flex-1">
            <span class="text-gray-600 font-thin">{{ facebookPage.name }}</span>
            <div v-if="wasShared" class="mt-1 text-blue-600 font-thin text-xs">
                Shared the {{ wasSharedAt }} - <a :href="albumUrl" class="underline" target="_blank">See album on Facebook</a>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        props: ['project', 'facebookPage', 'form', 'last'],

        methods: {
            getSharingData()
            {
                if ( ! this.project ) {
                    return null;
                }
                
                for ( let i = 0; i < this.project.facebook_pages.length; i++ ){
                    if ( this.project.facebook_pages[i].id == this.facebookPage.id ) {
                        return this.project.facebook_pages[i];
                    }
                }

                return null;
            },

            toggle()
            {
                if ( this.wasShared ) {
                    return;
                }
                
                for ( let i = 0; i < this.form.facebook_pages.length; i++ ){
                    if ( this.form.facebook_pages[i] == this.facebookPage.id ) {
                        this.form.facebook_pages.splice(i, 1);
                        return;
                    }
                }

                this.form.facebook_pages.push(this.facebookPage.id);
            }
        },

        computed: {
            wasShared()
            {
                let data = this.getSharingData();
                if ( ! data ) {
                    return false;
                }
                
                return true;
            },

            wasSharedAt()
            {
                let data = this.getSharingData();
                if ( ! data ) {
                    return false;
                }
                
                return data.shared_at;
            },

            wasSharedAtDate()
            {
                let date = this.wasSharedAt()
                if ( date ) {
                    return window.dayjs(date).format('D MMMM');
                }

                return false;
            },

            wasSharedAtTime()
            {
                let time = this.wasSharedAt()
                if ( time ) {
                    return window.dayjs(time).format('HH:MM');
                }

                return false;
            },

            albumUrl()
            {
                let data = this.getSharingData();
                if ( ! data ) {
                    return false;
                }
                
                return data.album_url;
            },

            isSelected()
            {
                for ( let i = 0; i < this.form.facebook_pages.length; i++ ){
                    if ( this.form.facebook_pages[i] == this.facebookPage.id ) {
                        return true;
                    }
                }

                return false;
            },
        }
    }
</script>
