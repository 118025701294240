import Vue from 'vue';

require("./_spa");

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

require('~spa/_bootstrap');
require('~spa/_string-helpers');
require('~spa/_vue-bootstrap');
require('./bootstrap');

import App from './components/App';
import router from './_router';
import i18n from './_i18n';
import store from './store';

window.App = new Vue({
    el: '#app',
    template: '<App/>',
    components: {
        App,
    },
    i18n,
    store,
    router,
    created() {
        window.addEventListener('online', (event) => {
            window.Toasteo.success(
                this.$t('offline_mode.online')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
        window.addEventListener('offline', (event) => {
            window.Toasteo.warning(
                this.$t('offline_mode.offline')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });

        // Detect class changes on <html> to see if Google Translate is active or not.
        var observer = new MutationObserver(() => {
            if ( document.documentElement.classList.contains('translated-ltr') ) {
                this.$store.commit('setGoogleTranslateStatus', true);
            }
        })

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        })

        var that = this;
        setInterval(function () {
            if ( that.$store?.state?.user?.locale ) {
                let locale = that.$store.state.user.locale.split('-').pop();
                if ( locale == 'be' ) {
                    locale = 'fr';
                }
                if ( window.moment ) {
                    window.moment.locale(locale);
                }
                if ( window.App.$moment() ) {
                    window.App.$moment().locale(locale);
                }
            }
        }, 1000);
    }
}).$mount('#app');
