import FacebookPages from '~base/components/Professionals/Visibility/FacebookPages/Edit';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'facebook-pages',
        component: FacebookPages,
        'name': 'professional.visibility.facebook-pages.edit',
        meta: {
            title: '{professionalname} - Facebook Pages'
        }
    }).protected().get()
];