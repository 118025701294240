<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('visibility.statistics.show.title') }}</template>

        <template v-slot:tabs v-if="website_statistics">
            <a href="#" @click.prevent="setTab('trustup')" :class="$spa.css.layout.page_tabs.tab + (tab == 'trustup' ? ' '+$spa.css.layout.page_tabs.tab_active : '')">
                {{ $t('visibility.statistics.show.tabs.trustup') }}
            </a>
            <a href="#" @click.prevent="setTab('websites')" :class="$spa.css.layout.page_tabs.tab + (tab == 'websites' ? ' '+$spa.css.layout.page_tabs.tab_active : '')">
                {{ $t('visibility.statistics.show.tabs.websites') }}
            </a>
        </template>

        <template v-slot:content>
            <div class="mb-8" v-if="tab == 'trustup'">
                <v-spa-panel :loading="loading" v-if="loading"></v-spa-panel>
                <template v-if="! loading">
                    <template v-if="! statistics">
                        <v-spa-panel>
                            <div class="mx-auto py-12">
                                <div class="w-[400px] mx-auto">
                                    <v-spa-lottie-empty-2></v-spa-lottie-empty-2>
                                </div>
<!--                                <p class="text-center mt-8 text-2xl font-medium">{{ $t('visibility.statistics.show.trustup.empty') }}</p>-->
                                <p class="my-12 text-lg font-thin text-center text-gray-700">{{ $t('visibility.statistics.show.trustup.empty') }}</p>
                            </div>
                        </v-spa-panel>
                    </template>
                    <template v-if="statistics">
                        <div class="flex flex-wrap mb-4 -mx-2">
                            <div class="w-full px-2 mb-4 sm:w-1/2 sm:mb-0" v-for="(dataset, index) in statistics.datasets" :key="index">
                                <v-spa-panel :class="'hover:bg-gray-100'">
                                    <h2 class="mb-1 text-base text-gray-400 uppercase">{{ dataset.label }}</h2>
                                    <slot name="content">
                                        <p class="text-4xl font-thin" :style="`color: ${dataset.background_color}`">{{ getDatasetTotal(dataset) }}</p>
                                    </slot>
                                </v-spa-panel>
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <v-spa-panel>
                                <h2 class="mb-1 text-base text-gray-400 uppercase">{{ $t('visibility.statistics.show.panels.page') }}</h2>
                                <canvas id="statistics-canvas" :count="statistics.datasets.length"></canvas>
                                <chartjs-bar
                                    :datalabel="dataset.label"
                                    :data="dataset.data"
                                    :backgroundcolor="dataset.background_color"
                                    :bordercolor="dataset.color"
                                    :labels="statistics.labels"
                                    :key="index"
                                    target="statistics-canvas"
                                    v-for="(dataset, index) in statistics.datasets"
                                ></chartjs-bar>
                            </v-spa-panel>
                        </div>
                    </template>
                </template>
            </div>
            <template v-if="tab == 'websites'">
                <v-spa-panel :loading="loading" v-if="loading"></v-spa-panel>
                <div class="mb-8" v-for="site in website_statistics" :key="site.id">
                    <div class="flex flex-wrap mb-4 -mx-2">
                        <div class="w-full px-2 mb-4 sm:w-1/2 sm:mb-0 md:w-1/4" v-for="(dataset, index) in site.statistics.datasets" :key="index">
                            <v-spa-panel>
                                <h2 class="mb-1 text-base text-gray-400 uppercase">{{ dataset.label }}</h2>
                                <slot name="content">
                                    <p class="text-4xl font-thin" :style="`color: ${dataset.background_color}`">{{ getDatasetTotal(dataset) }}</p>
                                </slot>
                            </v-spa-panel>
                        </div>
                    </div>
                    <div class="hidden md:block">
                        <v-spa-panel>
                            <h2 class="mb-1 text-base text-gray-400 uppercase">{{ $t('visibility.statistics.show.panels.website', {name: site.name}) }}</h2>
                            <canvas :id="'website-statistics'+site.id+'-canvas'" :count="site.statistics.datasets.length"></canvas>
                            <chartjs-line
                                :datalabel="getWebsiteSessions(site).label"
                                :data="getWebsiteSessions(site).data"
                                :backgroundcolor="getWebsiteSessions(site).background_color"
                                :bordercolor="getWebsiteSessions(site).border_color"
                                :pointbackgroundcolor="getWebsiteSessions(site).border_color"
                                :pointborderwidth="4"
                                :labels="site.statistics.labels"
                                :target="'website-statistics'+site.id+'-canvas'"
                            ></chartjs-line>
                            <chartjs-bar
                                :datalabel="dataset.label"
                                :data="dataset.data"
                                :backgroundcolor="dataset.background_color"
                                :bordercolor="dataset.border_color"
                                :labels="site.statistics.labels"
                                :target="'website-statistics'+site.id+'-canvas'"
                                :key="index"
                                v-for="(dataset, index) in getWebsiteBarSessions(site)"
                            ></chartjs-bar>
                        </v-spa-panel>
                    </div>
                </div>
            </template>
        </template>
    </v-spa-layout-page>
</template>

<script>

    import Vue from 'vue';
    require('chart.js');
    require('hchs-vue-charts');
    Vue.use(VueCharts);

    import mixin from '~spa/components/_Table/index-mixin.js';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';

    export default {
        mixins: [mixin],

        components: {
            'tmp-spa-page': Page
        },


        data() {
            return {
                tab: 'trustup',
                statistics: null,
                website_statistics: null
            }
        },

        methods: {
            setTab(tab)
            {
                this.tab = tab;
            },

            getDatasetTotal(dataset)
            {
                return dataset.data.reduce(function(a, b) { return parseInt(a) + parseInt(b); }, 0);
            },

            getWebsiteSessions(site)
            {
                return site.statistics.datasets.filter(dataset => dataset.type == 'sessions')[0];
            },

            getWebsiteBarSessions(site)
            {
                return site.statistics.datasets.filter(dataset => dataset.type !== 'sessions');
            },

            getStatistics()
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('getStatistics', {professionalId: this.$store.state.professional.id})
                        .then(statistics => {
                            this.statistics = statistics;
                            if ( this.statistics && this.statistics.datasets ) {
                                this.statistics.datasets = this.statistics.datasets
                                    .filter(dataset => dataset.type == 'page' || dataset.type == 'phone')
                                    .map(dataset => {
                                        if ( dataset.type == 'page' ) {
                                            dataset.label = this.$t('visibility.statistics.show.trustup.labels.page');
                                            dataset.color = dataset.background_color = '#F3C648';
                                        }
                                        if ( dataset.type == 'phone' ) {
                                            dataset.label = this.$t('visibility.statistics.show.trustup.labels.phone');
                                            dataset.color = dataset.background_color = '#479488';
                                        }
                                        return dataset;
                                    });
                            }
                            resolve();
                        });
                });
            },

            getWebsiteStatistics()
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('getWebsiteStatistics', {professionalId: this.$store.state.professional.id})
                        .then(statistics => {
                            if ( ! statistics ) {
                                this.website_statistics = null;
                                return resolve();
                            }
                            this.website_statistics = statistics.sites.map(site => {
                                site.statistics.datasets.map(dataset => {
                                    if ( dataset.type == 'sessions' ) {
                                        dataset.label = this.$t('visibility.statistics.show.website.labels.sessions');
                                        // dataset.label = 'Total';
                                        dataset.border_color = dataset.background_color = '#4D71FB';
                                    }

                                    if ( dataset.type == 'sessions-seo' ) {
                                        dataset.label = this.$t('visibility.statistics.show.website.labels.sessions-seo');
                                        // dataset.label = 'Référencement naturel';
                                        dataset.border_color = dataset.background_color = '#E39724';
                                    }

                                    if ( dataset.type == 'sessions-social' ) {
                                        dataset.label = this.$t('visibility.statistics.show.website.labels.sessions-social');
                                        // dataset.label = 'Réseaux sociaux';
                                        dataset.border_color = dataset.background_color = '#67A7F2';
                                    }

                                    if ( dataset.type == 'sessions-adwords' ) {
                                        dataset.label = this.$t('visibility.statistics.show.website.labels.sessions-adwords');
                                        // dataset.label = 'Google Ads';
                                        dataset.border_color = dataset.background_color = '#73AD58';
                                    }

                                    // dataset.data = dataset.data.map(value => parseInt(value));

                                    return dataset;
                                });

                                return site;
                            });
                            resolve();
                        });
                });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-statistics');

            Promise.all([
                this.getStatistics(),
                this.getWebsiteStatistics()
            ]).then(() => this.loading = false);
        },

        beforeDestroy()
        {
            this.$forceUpdate();
        }
    }
</script>
