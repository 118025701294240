<template>
    <div>
        <h4 class="text-2xl mb-6">{{ $t('visibility.ratings.collect.title') }}</h4>

        <form @submit.prevent="submit">
            <div>
                <div
                    class="flex items-center max-w-input"
                    v-for="(email, index) in form.emails"
                    :key="index"
                >
                    <v-spa-input
                        type="email"
                        :form="form"
                        :id="'collect-rating-form-email-' + index"
                        input-name="emails"
                        :input-is-array="true"
                        :input-key="index"
                        :label="$t('form.labels.email')"
                        :placeholder="$t('form.placeholders.email')"
                        container-class-name="w-full mb-6"
                        label-container-class-name="w-full"
                        input-container-class-name="w-full"
                    ></v-spa-input>
                    <a class="px-2 py-1 ml-4 text-sm text-gray-600 bg-gray-100 hover:bg-gray-300 rounded" href="#" @click.prevent="removeEmail(index)" v-if="form.emails.length > 1 && (index ) !== form.emails.length">-</a>
                    <a class="px-2 py-1 ml-4 text-sm text-gray-600 bg-gray-100 hover:bg-gray-300 rounded" href="#" @click.prevent="addEmail" v-if="(index + 1) == form.emails.length">+</a>
                </div>
            </div>
            <div class="flex flex-row-reverse">
                <v-spa-loading-button :loading="form.is_loading" :is-disabled="containsEmptyEmails()">{{ $t('visibility.ratings.collect.button') }}</v-spa-loading-button>
            </div>
        </form>
    </div>
</template>

<script>
    import Form from '~spa/classes/Form';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';
    import Row from "./_Row";
    import CardVue from "./Card";

    export default {

        components: {
            'tmp-spa-page': Page
        },

        data() {
            return {
                form: new Form({
                    emails: ['']
                })
            }
        },
        methods: {
            containsEmptyEmails() {
                return this.form.emails.some((email) => email == '' || email == null);
            },

            addEmail()
            {
                this.form.emails.push('');
            },

            removeEmail(index)
            {
                this.form.emails.splice(index, 1);
            },

            submit()
            {
                if ( this.containsEmptyEmails() ) {
                    console.log(this.form.emails);
                    window.Toasteo.warning('Some emails are empty');
                    return;
                }

                if ( this.form.isLoading() ) {
                    return;
                }

                this.form.load();

                this.form.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/ratings/collect` )
                    .then((response) => {
                        window.Toasteo.success( this.$t('visibility.ratings.collect.success') );
                        this.form.emails = [''];
                        this.form.stop();
                        this.$emit('success');
                        this.$modal.hide('rating-collect-modal');
                    })
                    .catch((error) => {
                        console.log(error);
                        this.form.stop();
                    });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-ratings');
        }
    }
</script>
