<template>
    <informations-base>
        <div class="max-w-800" slot="informations-content">
            <v-spa-panel :title="$t('visibility.informations.visuals.logo.title')">
                <div class="flex flex-wrap justify-center py-2" v-if="$store.state.professional.logo && ! edit">
                    <img :src="$store.state.professional.logo" class="mb-2" style="max-width: 200px; max-height: 200px;" @click.prevent="edit = true">
                    <p class="w-full text-sm text-center text-gray-700">{{ $t('visibility.informations.visuals.logo.edit_helper') }}</p>
                </div>
                <form @submit.prevent="update" v-else>
                    <div class="mb-4">
                        <v-spa-crop ref="cropLogo" class-name="logo" :size="{width: '450px', height: '450px'}">
                        </v-spa-crop>
                    </div>
                    <div class="flex items-center justify-end">
                        <a href="#" class="inline-block mr-4 text-gray-600" @click.prevent="edit = false" v-if="$store.state.professional.logo">{{ $t('visibility.informations.visuals.logo.cancel') }}</a>
                        <v-spa-loading-button :loading="loading">{{ $t('visibility.informations.visuals.logo.button') }}</v-spa-loading-button>
                    </div>
                </form>
            </v-spa-panel>
        </div>
    </informations-base>
</template>

<script>

    import Base from './Base';
    import mixin from './visual-mixin';

    export default {
        components: {
            'informations-base': Base
        },

        mixins: [mixin],

        methods: {
            update()
            {
                this.updateVisual('logo', this.$refs.cropLogo.getCroppedResult());
            },
        },

        created() {
            //
        }
    }
</script>