<template>
    <div>
        <v-spa-table-loader v-if="loading"></v-spa-table-loader>

        <form @submit.prevent="$emit('submitted')" v-else>
            <div>
                <v-spa-textarea id="form_answer" :form="form" input-name="answer"></v-spa-textarea>
                <v-spa-element id="form_type" :label="$t('form.labels.automatic_answer_type')" :form="form" input-name="type">
                    <select :class="$spa.css.form.select" v-model="form.type">
                        <option value="conversation">{{ $t('form.options.automatic_answer_type.conversation') }}</option>
                        <option value="quote">{{ $t('form.options.automatic_answer_type.quote') }}</option>
                    </select>
                </v-spa-element>

                <v-spa-date-picker :popover="{ visibility: 'click' }"
                                   id="form_start-date" :form="form" input-name="start_date"
                ></v-spa-date-picker>

                <v-spa-date-picker :popover="{ visibility: 'click' }"
                                   id="form_end-date" :form="form" input-name="end_date"
                                   ></v-spa-date-picker>
            </div>
            <div class="flex flex-row-reverse mt-6">
                <v-spa-loading-button :loading="form.is_loading">{{ button }}</v-spa-loading-button>
            </div>
        </form>

    </div>
</template>

<script>
    import Form from '~spa/classes/Form';

    export default {
        data() {
            return {
                loading: false,
                form: new Form({
                    answer: '',
                    type: 'quote',
                    start_date: null,
                    end_date: null,
                    order: 1
                })
            }
        },

        props: ['answer', 'button'],

        methods: {
            dateFormatter(date)
            {
                return window.moment(date).format('YYYY-MM-DD HH:mm:ss');
            },

            dateFormatterDisplay(date)
            {
                return window.moment(date).format('D MMMM YYYY');
            }
        },

        created() {
            this.form.start_date = this.dateFormatter( window.moment() );
            this.form.end_date = this.dateFormatter( window.moment().add(14, 'days') );
        },

        mounted() {
            this.$emit('ready');
        }
    }
</script>
