<template>
    <informations-base>
        <template slot="informations-content">
            <v-spa-panel :title="$t('visibility.informations.workfields.title')" :loading="loading">
                <form @submit.prevent="update">
                    <template v-for="(workfield, index) in workfields">
                        <div class="block mb-4" :key="workfield.id">
                            <div>
                                <h3 class="mb-2 text-lg text-gray-600">{{ workfield.title }}</h3>
                                <label :for="'workfield-'+workfield.id">
                                    <input :id="'workfield-'+workfield.id" type="checkbox" @change="toggleWorkfield(workfield.id)" :checked="form.workfields.includes(workfield.id)">
                                    <span class="ml-2 text-sm text-gray-600" :class="{'text-gray-800': form.workfields.includes(workfield.id)}"> {{ workfield.title }}</span>
                                </label>
                            </div>
                            <div class="col-count-3" v-if="workfield.children && workfield.children.length > 0">
                                <label :for="'workfield-'+workfield.id + '-child-' + children.id" class="block" v-for="(children, childIndex) in workfield.children" :key="workfield.id + '-' + children.id">
                                    <input :id="'workfield-'+workfield.id + '-child-' + children.id" type="checkbox" @change="toggleWorkfield(children.id)" :checked="form.workfields.includes(children.id)">
                                    <span class="ml-2 text-sm text-gray-600" :class="{'text-gray-800': form.workfields.includes(children.id)}"> {{ children.title }}</span>
                                </label>
                            </div>
                        </div>
                    </template>
                    <div class="flex flex-row-reverse p-6">
                        <v-spa-loading-button :loading="form.is_loading">{{ $t('form.buttons.update') }}</v-spa-loading-button>
                    </div>
                </form>
            </v-spa-panel>
        </template>
    </informations-base>
</template>

<script>

    import Form from '~spa/classes/Form';
    import Base from './Base';

    export default {
        components: {
            'informations-base': Base
        },

        data() {
            return {
                workfields: [],
                loading: true,
                form: new Form({
                    workfields: []
                })
            }
        },

        methods: {
            getWorkfieldsAsTree()
            {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getWorkfieldsAsTree')
                        .then((workfields) => {
                            this.workfields = workfields;
                            resolve();
                        });
                });
            },

            getProfessionalWorkfieldIds()
            {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getProfessionalWorkfieldsIds', {professionalId: this.$store.state.professional.id})
                        .then((ids) => {
                            this.form.workfields = ids;
                            resolve();
                        });
                });
            },

            toggleWorkfield(workfield)
            {
                let index = this.form.workfields.indexOf(workfield);
                if ( index !== -1 ) {
                    this.form.workfields.splice(index, 1);
                    return;
                }

                this.form.workfields.push(workfield);
            },
            
            update() {
                this.form.put(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/workfields`)   
                    .then((response) => {
                        window.Toasteo.success( this.$t('visibility.informations.workfields.success') );
                    });
            }
        },

        created() {
            Promise.all([this.getWorkfieldsAsTree(), this.getProfessionalWorkfieldIds()])
                .then(() => this.loading = false);
        }
    }
</script>