export default {

    getAutomaticAnswers(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/automatic-answers/`,
            key: 'automatic_answers'
        });
    },

    getAutomaticAnswer(context, {professionalId, answerId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/automatic-answers/${answerId}`,
            key: 'automatic_answer'
        });
    },

    deleteAutomaticAnswer(context, {professionalId, answerId})
    {
        return context.dispatch('deleteData', {
            url: `${window.api_url}/me/professionals/${professionalId}/automatic-answers/${answerId}`
        });
    },

    getQuickAnswers(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/quick-answers/`,
            key: 'quick_answers'
        });
    },

    getQuickAnswer(context, {professionalId, answerId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/quick-answers/${answerId}`,
            key: 'quick_answer'
        });
    },

    deleteQuickAnswer(context, {professionalId, answerId})
    {
        return context.dispatch('deleteData', {
            url: `${window.api_url}/me/professionals/${professionalId}/quick-answers/${answerId}`
        });
    },
    
    makeDefaultQuickAnswers(context, {professionalId, force = false})
    {
        return context.dispatch('postData', {
            url: `${window.api_url}/me/professionals/${professionalId}/quick-answers/default?force=${force}`
        });
    },

}