<template>
    <div class="requests-show" :class="{'flex flex-col h-full': ! loading}">
        <v-spa-panel :loading="loading" v-if="loading" :class="'py-32'"></v-spa-panel>
        <template v-else>
            <template v-if="error">
                <v-spa-panel>
                    <div class="flex flex-wrap items-center h-full mx-4 lg:p-8">
                        <div class="hidden w-full px-4 mb-8 sm:block md:mb-0 md:w-1/3 lg:w-1/2 xl:w-1/3">
                            <img src="/images/not_found.svg" style="max-height: 350px; max-width: 250px;" class="w-full mx-auto">
                        </div>
                        <div class="w-full px-4 md:w-2/3 lg:w-1/2 xl:w-2/3">
                            <h1 class="mb-4 text-xl text-center text-gray-800 sm:text-2xl md:text-left">{{ $t('inbox.requests.show.error.title') }}</h1>
                            <p class="text-base text-center text-gray-600 sm:text-lg md:text-left md:text-base">{{ $t('inbox.requests.show.error.text') }}</p>
                        </div>
                    </div>
                </v-spa-panel>
            </template>
            <template v-else>
                <div class="flex items-center justify-between w-full px-6 py-4 bg-white border-b-2 border-gray-200 flew-grow-0">
                    <span class="w-full text-base md:text-lg flex-no-overflow md:break-words-gracefully" :class="[responsiveSidebar ? 'break-words-gracefully' : 'truncate md:no-truncate']" v-if="model.title">
                        {{ model.title }}
                        <template v-if="model.user && model.user.full_name">
                            - {{ model.user.full_name }}
                        </template>
                    </span>
                    <div class="w-[40px] flex justify-end flex-shrink-0 xl:hidden">
                        <svg v-if="! responsiveSidebar" @click.prevent="responsiveSidebar = ! responsiveSidebar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6 text-gray-800 cursor-pointer devfont devfont-circle-info"><circle cx="12" cy="12" r="9"></circle><path d="M12 12v4"></path><path d="M12 8h.01"></path></svg>
                        <svg v-if="responsiveSidebar" @click.prevent="responsiveSidebar = ! responsiveSidebar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6 text-gray-800 cursor-pointer devfont devfont-x"><path d="M7 7l10 10"></path><path d="M17 7L7 17"></path></svg>
                    </div>
                    <div class="justify-end hidden xl:flex items-stretch flew-shrink-0 w-[250px]">
                        <div class="mr-2">
                            <v-spa-loading-button class-name="flex items-center p-3 bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-800 rounded relative" @clicked="openPrintablePage">
                                <i class="mr-2 text-sm fas fa-print"></i>
                                {{ $t('inbox.requests.show.buttons.print') }}
                            </v-spa-loading-button>
                        </div>
                        <status-badge :model="model" @update="updateStatus"></status-badge>
                    </div>
                </div>
                <div class="relative flex-1 overflow-y-hidden">
                    <div class="flex items-stretch w-full h-full">

                        <div class="w-full md:flex-1">
                            <div class="flex flex-col h-full">
                                <div id="conversation-chat" class="flex-1 p-8 overflow-y-auto bg-white">
                                    <div class="p-4 mx-auto mb-8 bg-white border-t-4 rounded-lg shadow max-w-500 border-trustup" v-if="model.chat && ! model.chat.answerable">
                                        <div class="flex items-center w-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 w-8 h-8 mr-4 text-gray-600 devfont devfont-circle-info"><circle cx="12" cy="12" r="9"></circle><path d="M12 12v4"></path><path d="M12 8h.01"></path></svg>
                                            <p class="font-thin text-gray-700" v-if="model.source == 'website-conversation'">
                                                {{ $t('inbox.requests.show.panels.website_message.text') }}
                                            </p>
                                            <p class="font-thin text-gray-700" v-else>
                                                {{ $t('inbox.requests.show.panels.guest_quote.text') }}
                                            </p>
                                        </div>
                                    </div>

                                    <website-form-submission v-if="model.source == 'website-form-submission'" :model="model"></website-form-submission>

                                    <quote-description v-if="model.source == 'quote'" :model="model"></quote-description>

                                    <conversation-chat :messages="model.chat.messages" v-if="model.chat && model.chat.messages && model.chat.messages.length > 0"></conversation-chat>
                                </div>

                                <v-answer-form :model="model" @require-scroll="scrollToBottom" @new-message="addMessage" v-if="model.chat && model.chat.answerable"></v-answer-form>
                            </div>
                        </div>

                        <div class="hidden w-[300px] bg-white border-l border-gray-200 xl:block">
                            <v-author :model="model" v-if="model.user"></v-author>
                            <quote-address :model="model" v-if="model.source == 'quote'"></quote-address>
                            <conversation-medias :model="model"></conversation-medias>
                        </div>
                    </div>

                    <div class="absolute inset-0 bg-white xl:hidden oveflow-y-auto" :class="{'block': responsiveSidebar, 'hidden': ! responsiveSidebar}">
                        <div class="p-4 border-b border-gray-100">
                            <status-badge submenu-position="left-0" :model="model" @update="updateStatus"></status-badge>
                        </div>
                        <v-author :model="model"></v-author>
                        <quote-address :model="model" v-if="model.source == 'quote'"></quote-address>
                        <conversation-medias :model="model"></conversation-medias>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex';
    import Map from '~base/classes/Map';
    import Form from '~spa/classes/Form';
    import Layout from '~base/components/Layout';
    import mixin from '~spa/components/_Table/show-mixin.js';
    import QuoteDescription from './Show/QuoteDescription.vue';
    import ConversationVue from './Show/Conversation.vue';
    import AnswerForm from './Show/AnswerForm.vue';
    import Author from './Show/Author.vue';
    import Chat from './_Partials/Chat.vue';
    import ConversationMedias from './Show/ConversationMedias.vue';
    import QuoteInformationsVue from './Show/QuoteInformations.vue';
    import QuoteAddressVue from './Show/QuoteAddress.vue';
    import WebsiteMessageVue from './Show/WebsiteMessage.vue';
    import WebsiteFormSubmission from './Show/WebsiteFormSubmission.vue';
    import StatusBadge from './Show/StatusBadge.vue';
    import GuestQuoteVue from './Show/GuestQuote.vue';

    export default {
        components: {
            'v-answer-form': AnswerForm,
            'v-author': Author,
            'conversation-medias': ConversationMedias,
            'conversation-chat': Chat,
            'website-message': WebsiteMessageVue,
            'website-form-submission': WebsiteFormSubmission,
            'status-badge': StatusBadge,
            'quote-description': QuoteDescription,
            'guest-quote': GuestQuoteVue,
            'conversation': ConversationVue,
            'quote-informations': QuoteInformationsVue,
            'quote-address': QuoteAddressVue
        },

        data() {
            return {
                error: false,
                responsiveSidebar: false,
                displayFullTitle: false,
                actions: {
                    archive: false,
                    favorite: false
                }
            }
        },

        mixins: [mixin],

        methods: {
            updateStatus(status) {
                this.model.status = status;

                this.$store.dispatch('updateRequestStatus', {professionalId: this.$store.state.professional.id, requestId: this.model.id, status: status});
            },

            toggleArchive() {
                this.actions.archive = true;

                this.$store.dispatch('toggleRequestArchive', {professionalId: this.$store.state.professional.id, requestId: this.model.id})
                    .then(() => {
                        if ( this.model.archived_at ) {
                            window.Toasteo.success( this.$t('inbox.requests.success_messages.unarchived') );
                            this.model.archived_at = null;
                        } else {
                            window.Toasteo.success( this.$t('inbox.requests.success_messages.archived') );
                            this.model.archived_at = moment().format('YYYY-MM-DD HH:ii:ss');
                        }

                        this.actions.archive = false;
                    });
            },

            toggleFavorite() {
                this.actions.favorite = true;

                this.$store.dispatch('toggleRequestFavorite', {professionalId: this.$store.state.professional.id, requestId: this.model.id})
                    .then(() => {
                        if ( this.model.favorited_at ) {
                            window.Toasteo.success( this.$t('inbox.requests.success_messages.unfavorited') );
                            this.model.favorited_at = null;
                        } else {
                            window.Toasteo.success( this.$t('inbox.requests.success_messages.favorited') );
                            this.model.favorited_at = moment().format('YYYY-MM-DD HH:ii:ss');
                        }

                        this.actions.favorite = false;
                    });
            },

            setSidebarSection()
            {
                if ( this.model.deleted_at ) {
                    return this.$store.commit('SIDEBAR/SET_SECTION', 'inbox-trash');
                }

                if ( this.model.archived_at ) {
                    return this.$store.commit('SIDEBAR/SET_SECTION', 'inbox-archives');
                }

                return this.$store.commit('SIDEBAR/SET_SECTION', 'inbox-requests');
            },

            openPrintablePage()
            {
                let routeData = this.$router.resolve({name: 'professional.inbox.requests.print', params: {professional: this.$store.state.professional.id, request: this.model.id}});
                window.open(routeData.href, '_blank');
            },

            addMessage({request, message})
            {
                this.model.status = request.status;
                this.model.chat.messages.push(message);
                this.scrollToBottom();
            },

            scrollToBottom()
            {
                this.$nextTick(() => {
                    var container = document.getElementById("conversation-chat");
                    if ( container ) {
                        container.scrollTop = container.scrollHeight;
                    }
                });
            }
        },

        created() {
            this.$store.dispatch('getRequest', {professionalId: this.$store.state.professional.id, requestId: this.$route.params.request})
                .then(request => {
                    this.$store.state.layout.container = false;
                    this.model = request;
                    if ( ! this.model.read_at ) {
                        this.$store.state.professional.unread_requests_count--;
                    }

                    this.$store.dispatch('markRequestAsRead', {professionalId: this.$store.state.professional.id, requestId: this.model.id});
                    this.loading = false;
                    this.scrollToBottom();
                    this.setSidebarSection();
                })
                .catch(error => {
                    this.error = true;
                    this.loading = false;
                });
        }
    }
</script>
