<template>
    <v-spa-layout-page :backLink="{name: 'professional.visibility.projects.index'}" :contentPadding="false" :contentContainerClass="'lg:!px-0 !mt-0'">
       <template v-slot:title>{{ $t('visibility.projects.edit.title') }}</template>

        <template v-slot:content>
            <project-form ref="form" @ready="getProject" @submitted="update" :project="project" :button="$t('table.actions.save')"></project-form>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import ProjectForm from './Form';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';

    export default {
        components: {
            'project-form': ProjectForm,
            'tmp-spa-page': Page
        },

        data() {
            return {
                project: null
            }
        },

        methods: {
            getProject()
            {
                this.$store.dispatch('getProject', {professionalId: this.$store.state.professional.id, projectId: this.$route.params.project})
                    .then(project => {
                        this.project = project;
                        this.$refs.form.form.fill({
                            title: project.title,
                            description: project.description,
                            images: project.images,
                            website_albums: this.getProjectWebsiteAlbums(),
                            facebook_pages: project.facebook_pages.map(page => page.id)
                        });
                        this.$refs.form.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                        window.Toasteo.error('Project #' + this.$route.params.project + ' not found.');
                        this.$router.push({name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}});
                    });
            },

            getProjectWebsiteAlbums()
            {
                let ids = [];

                for ( let i = 0; i < this.$refs.form.sites.length; i++ ) {
                    for ( let n = 0; n < this.$refs.form.sites[i].albums.length; n++ ) {
                        if ( Array.isArray(this.$refs.form.sites[i].albums[n].projects) && this.$refs.form.sites[i].albums[n].projects.includes(this.project.id) ) {
                            ids.push(this.$refs.form.sites[i].albums[n].id);
                        }
                    }
                }

                return ids;
            },

            update()
            {
                this.$refs.form.form.put( `${window.api_url}/me/professionals/${this.$store.state.professional.id}/projects/${this.project.id}` )
                    .then((response) => {
                        this.$refs.form.success(this.$t('visibility.projects.edit.success'), {name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}});
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$refs.form.form.stop();
                    })
            }
        },
        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-projects');

            this.$bus.$on('ctrl-s', (resource) => {
                this.update();
            });
        },
        beforeDestroy() {
            this.$bus.$off('ctrl-s');
        }
    }
</script>
