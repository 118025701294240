import Base from './actions/base';
import Auth from './actions/auth';
import Professionals from './actions/professionals';
import Projects from './actions/projects';
import FacebookPages from './actions/facebook-pages';
import WebsiteAlbums from './actions/website-albums';
import Ratings from './actions/ratings';
import Statistics from './actions/statistics';
import Requests from './actions/requests';
import Settings from './actions/settings';
import Voip from './actions/voip';
import Facebook from './actions/facebook';

const actions = Object.assign(
    Base,
    Auth,
    Professionals,
    Ratings,
    Statistics,
    Requests,
    Settings,
    Projects,
    FacebookPages,
    WebsiteAlbums,
    Voip,
    Facebook
);

/**
 * These are methods can be be used globally.
 * They can contain asynchronous operations.
 * They will commit a mutation instead of mutating the state directly.
 */
export default actions;