export default class ResizableTextarea {
    constructor({id, focus = true})
    {
        this.id = id;
        this.textarea = document.getElementById(id);
        this.focus = focus;
    }

    init()
    {
        this.textarea.addEventListener('change',  () => this.resize(), false);
        this.textarea.addEventListener('cut',     () => this.delayedResize(), false);
        this.textarea.addEventListener('paste',   () => this.delayedResize(), false);
        this.textarea.addEventListener('drop',    () => this.delayedResize(), false);
        this.textarea.addEventListener('keydown', () => this.delayedResize(), false);

        if ( this.focus ) {
            this.textarea.focus();
            this.textarea.select();
        }
        this.resize();
    }
    resize ()
    {
        this.textarea.style.height = 'auto';
        this.textarea.style.height = this.textarea.scrollHeight+'px';
    }
    delayedResize ()
    {
        window.setTimeout(() => this.resize(), 0);
    }
}