import Homepage from '~base/components/Professionals/Visibility/Homepage';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'homepage',
        component: Homepage,
        'name': 'professional.visibility.homepage',
        meta: {
            title: '{professionalname} - Homepage'
        }
    }).protected().get()
];