<template>
    <div>
        <div class="mb-3 text-center">
            <p class="text-sm font-thin text-gray-600">{{ model.received_at | moment('YYYY-MM-DD HH:mm:ss').format('dddd D MMMM YYYY') }}</p>
        </div>
        <div class="p-4 mx-auto mb-8 bg-white border-t-4 rounded-lg shadow max-w-500 border-trustup">
            <div v-if="model.data && model.data.site">
                <span class="font-bold">{{ $t('inbox.requests.show.panels.website_form_submission.site') }}:</span>
                {{ model.data.site.name }}
            </div>
            <div v-if="model.data && model.data.form">
                <span class="font-bold">{{ $t('inbox.requests.show.panels.website_form_submission.form') }}:</span>
                {{ model.data.form.title }}
            </div>
            <template v-if="model.data && model.data.data">
                <div v-for="(value, key) in model.data.data" :key="key">
                    <span class="font-bold">{{ prettyKey(key) }}:</span>
                    {{ value }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['model'],
        methods: {
            prettyKey(key)
            {
                var words = key.split('_');

                for (var i = 0; i < words.length; i++) {
                    var word = words[i];
                    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
                }

                return words.join(' ');
            }
        }
    }
</script>