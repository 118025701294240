<template>
    <informations-base>
        <template slot="informations-content">
            <v-spa-panel :loading="true" v-if="loading"></v-spa-panel>
            <div class="flex flex-wrap items-stretch -mx-2" v-else>
                <div class="w-full px-2 mb-4 md:w-1/2 lg:w-1/3" v-for="notification in notifications" :key="notification.namespace">
                    <v-spa-panel class="max-h-[400px] overflow-y-auto" :title="notification.title">
                        <!-- <div class="mb-1 text-lg">{{ notification.title }}</div> -->
                        <p class="mb-4 text-sm text-gray-700">{{ notification.description }}</p>
                        <template v-for="(preferences, userId) in notification.preferences">
                            <fieldset class="mb-1" v-for="(preference, method) in preferences" :key="`${notification.namespace}-${userId}-${method}`">
                                <a href="#" @click.prevent="save(notification, preference, method)" class="flex items-center w-full py-2 text-sm text-gray-800 group" :class="{'cursor-default': ! preference.editable}">
                                    <span class="flex items-center justify-center w-4 h-4 mr-2 border-2 rounded-full flex-no-shrink " :class="{ 'border-gray-200': ! preference.editable, 'border-gray-400 group-hover:border-primary' : preference.editable, 'border-primary': preference.status, 'border-gray-200': ! preference.status }">
                                        <template v-if="preference.status">
                                            <span class="w-2 h-2 rounded-full bg-primary"></span>
                                        </template>
                                    </span>
                                    <div class="flex-1">
                                        <span class="font-thin text-gray-600">{{ $t(`visibility.notification_preferences.labels.${preference.key}`) }} <span v-if="preference.value">({{ preference.value }})</span></span>
                                    </div>
                                </a>
                            </fieldset>
                        </template>
                    </v-spa-panel>
                </div>
            </div>
        </template>
    </informations-base>
</template>

<script>
    import Base from './Base';

    export default {
        components: {
            'informations-base': Base
        },

        data() {
            return {
                locales: [],
                loading: true,
                saving: null,
                notifications: []
            }
        },

        methods: {
            getPreferences()
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('getNotificationPreferences', {professionalId: this.$store.state.professional.id})
                        .then((notifications) => {
                            this.notifications = notifications;
                            resolve();
                        })
                });
            },

            save(notification, preference, method)
            {
                if ( ! preference.editable ) {
                    return;
                }

                let status = ! preference.status;
                this.saving = notification.namespace + '-' + method;
                axios.put(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/notification-preferences`, {namespace: notification.namespace, status: status, method: method})
                    .then(response => {
                        preference.status = status;
                        window.Toasteo.success(this.$t('visibility.informations.notifications.success'));
                        this.saving = false;
                    });
            }
        },

        created() {
            this.getPreferences()
                .then(() => this.loading = false);
        }
    }
</script>