export default {
    data() {
        return {
            loading: false,
            edit: false
        }
    },

    methods: {
        async updateVisual(key, source)
        {
            this.loading = true;

            let {data} = await this.$store.dispatch('updateData', {
                    url: `${window.api_url}/me/professionals/${this.$store.state.professional.id}/${key}`,
                    data: {
                        [key]: source
                    }
                });

            this.$store.state.professional[key] = data[key];
            window.Toasteo.success( this.$t('visibility.informations.visuals.'+key+'.success') );
            this.loading = false;
            this.edit = false;
        },
    }
}
