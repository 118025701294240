<template>
    <div class="flex flex-col items-center">
        <div class="text-red-600 h-5 w-5">
            <img :src="iconUrl" class="w-5 h-5">
        </div>
        <span class="text-sm" :class="{ 'font-bold': isMissed }">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        isMissed: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        text()
        {
            return this.isMissed
                ? this.$t('voip.calls.types.missed')
                : this.$t('voip.calls.types.incoming')
        },
        iconUrl()
        {
            const icon = this.isMissed
                ? 'arrow-left'
                : 'arrow-right'
            
            return `/icons/small/${icon}.svg`
        },
        color()
        {
            return this.isMissed
                ? 'text-red-500'
                : 'text-green-500'
        }
    }
}
</script>

<style>

</style>