import Calls from '~base/components/Professionals/Voip/Calls';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

const routes = [
    generator.create({
        path: 'calls',
        component: Calls,
        name: 'professional.inbox.voip.calls.index',
        meta: {
            title: '{professionalname} - Voip calls'
        }
    }).protected().get() 
];

export default routes;