export default class Map {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(zoom = 9, options) {
        this.styles = [{ "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#c6c6c6" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#d3ecf0" }, { "visibility": "on" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#d8eed8" }, { "visibility": "on" }] }];

        this.defaultZoom = zoom;
        this.zoom = zoom;
        this.maxZoom = 16;
        this.minZoom = 8;
        this.object = null;
        this.cluster = null;
        this.markers = [];

        let defaults = {
            styles: this.styles,
            disableDefaultUI: true,
            scrollwheel: false,
            fullscreenControl: true,
            disableAutoPan: false,
            maxZoom: this.maxZoom,
            minZoom: this.minZoom
        }
        this.options = Object.assign({}, defaults, options);

        this.intervention_zone = {
            fillOpacity: 0.25,
            fillColor: '#3a98d9',
            strokeColor: '#3a98d9',
            strokeOpacity: 0.35,
            strokeWeigth: 1
        }

        this.center = {
            lat: 0,
            lng: 0
        }

        this.infoWindow = {
            type: 'professional',
            content: '',
            position: {
                lat: 0,
                lng: 0
            },
            is_open: false,
            options: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }
        }

        this.hasMoved = false;
    }

    zoomIn() {
        if ( this.atMaxZoom() ) {
            return;
        }

        this.zoom++;
        this.object.setZoom(this.zoom);
    }

    zoomOut() {
        if ( this.atMinZoom() ) {
            return;
        }

        this.zoom--;
        this.object.setZoom(this.zoom);
    }

    atMaxZoom() {
        return this.zoom >= this.maxZoom;
    }

    atMinZoom() {
        return this.zoom <= this.minZoom;
    }

    resetZoom() {
        this.zoom = this.defaultZoom;
    }

    getUserLocationIcon() {
        return {
            url: '/icons/map/user.svg',
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new google.maps.Point(0, 0),
            zIndex: 2
        };
    }

    getProfessionalIcon(isPremium = true) {
        if ( isPremium ) {
            return this.getPremiumProfessionalIcon();
        }

        return this.getFreeProfessionalIcon();
    }

    getPremiumProfessionalIcon() {
        return {
            url: '/icons/map/professional.svg',
            scaledSize: new window.google.maps.Size(35, 35),
            origin: new google.maps.Point(0, 0),
            zIndex: 2
        };
    }

    getFreeProfessionalIcon() {
        return {
            url: '/icons/map/free-professional.svg',
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new google.maps.Point(0, 0),
            zIndex: 2
        };
    }

    getProfessionalIconHover(isPremium = true) {
        if ( isPremium ) {
            return this.getPremiumProfessionalIconHover();
        }

        return this.getFreeProfessionalIconHover();
    }

    getPremiumProfessionalIconHover() {
        return {
            url: '/icons/map/professional--hover.svg',
            scaledSize: new window.google.maps.Size(35, 35),
            origin: new google.maps.Point(0, 0),
            zIndex: 3
        };
    }

    getFreeProfessionalIconHover() {
        return {
            url: '/icons/map/free-professional--hover.svg',
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new google.maps.Point(0, 0),
            zIndex: 3
        };
    }

    getProfessionalIconSelected(isPremium = true) {
        if ( isPremium ) {
            return this.getPremiumProfessionalIconSelected();
        }

        return this.getFreeProfessionalIconSelected();
    }

    getPremiumProfessionalIconSelected() {
        return {
            url: '/icons/map/professional--selected.svg',
            scaledSize: new window.google.maps.Size(35, 35),
            origin: new google.maps.Point(0, 0),
            zIndex: 3
        };
    }

    getFreeProfessionalIconSelected() {
        return {
            url: '/icons/map/free-professional--selected.svg',
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new google.maps.Point(0, 0),
            zIndex: 3
        };
    }

    getClusterIcon() {
        return {
            width: 30,
            height: 30,
            url: '/icons/map/cluster.svg',
            anchorText: [-3, 0],
            textColor: 'white',
            textSize: 12
        }
    }

    getProjectIcon() {
        return {
            url: '/icons/map/project.svg',
            origin: new google.maps.Point(0, 0),
            scaledSize: new window.google.maps.Size(20, 20)
        };
    }

    getClusterIconUrl() {
        return 'icons/map/cluster.svg';
    }

    getClusterIconSize() {
        return 35;
    }

    getProfessionalInfoWindow(professional) {
        return '<div><h1>' + professional.company + '</h1></div>';
    }

    getProjectInfoWindow(project) {
        return '<div><h2>' + project.company + '</h2></div>';
    }

    createMap(id) {
        this.object = new google.maps.Map(document.getElementById(id), {
            center: {lat: this.center.lat, lng: this.center.lng},
            zoom: this.zoom,
            disableDefaultUI: this.options.disableDefaultUI,
            disableAutoPan: this.options.disableAutoPan,
            scrollwheel: this.options.scrollwheel,
            styles: this.options.styles
        });
    }

    fitBounds(bounds, padding = 0) {
        console.log('Fit bounds', this.object, bounds);
        this.object.fitBounds(bounds, 0);
    }

    withMarkerCluster(MarkerClusterer)
    {
        this.MarkerClusterer = MarkerClusterer;
    }

    createCluster() {
        this.cluster = new this.MarkerClusterer(
            this.object,
            this.markers,
            {
                zoomOnClick: true,
                maxZoom: 9,
                minimumClusterSize: 5,
                styles: [
                    {
                        textColor: 'white',
                        url: this.getClusterIconUrl(),
                        height: this.getClusterIconSize(),
                        width: this.getClusterIconSize()
                    },
                    {
                        textColor: 'white',
                        url: this.getClusterIconUrl(),
                        height: this.getClusterIconSize(),
                        width: this.getClusterIconSize()
                    },
                    {
                        textColor: 'white',
                        url: this.getClusterIconUrl(),
                        height: this.getClusterIconSize(),
                        width: this.getClusterIconSize()
                    }
                ]
            }
        );
    }

    createMarker(options)
    {
        options.map = this.object;

        let marker = new google.maps.Marker(options);
        this.markers.push(marker);

        return marker;
    }

    createMarkers(markers)
    {
        let array = [];
        for ( let i = 0; i < markers.length; i++ ) {
            array.push( this.createMarker(markers[i]) );
        }
        return array;
    }

    resetMarkers()
    {
        if ( this.cluster ) {
            this.cluster.clearMarkers();
        }

        for ( let i = 0; i < this.markers.length; i++ ) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
    }

    createCircle(options) {
        options.map = this.object;
        return new google.maps.Circle(options);
    }
}