<template>
    <div>
        <div class="relative">
            <div class="relative mb-2 bg-white"  v-if="$store.state.professional[update_visual_key]">
                <img :src="$store.state.professional[update_visual_key]" class="rounded" :class="image_class" :style="`height: ${crop_height}px`">
            </div>
            <div v-else>
                <div class="w-full bg-gray-300 rounded" :style="`height: ${crop_height}px`"></div>
            </div>

            <p class="absolute px-4 py-2 text-sm text-center text-white transition-opacity rounded-sm opacity-50 cursor-pointer bg-neutral-900 hover:opacity-100" :class="text_position" @click.prevent="$modal.show('modal-update-visual-' + update_visual_key); edit = true">
                <i class="fas fa-pencil"></i>
                 {{ $t('table.actions.edit') }}
            </p>
        </div>

        <portal :to="portal">
            <v-spa-mixed-modal :name="'modal-update-visual-' + update_visual_key" height="auto">

                <div class="mb-8 text-xl font-bold"><slot></slot></div>

                <form @submit.prevent="update">
                    <div class="mb-4 cursor-pointer">
                        <v-spa-crop :ref="'crop-'+update_visual_key" class-name="cover" :aspect-ratio="aspect_ratio"
                                    :crop-size="{width: crop_width, height: crop_height}"
                                    :size="{width: width+'px', height: height+'px'}">
                        </v-spa-crop>
                    </div>
                    <div class="flex items-center justify-end">
                        <a href="#" class="inline-block mr-4 text-gray-600" @click.prevent="edit = false; $modal.hide('modal-update-visual-' + update_visual_key);">{{ $t('visibility.informations.visuals.cover.cancel') }}</a>
                        <v-spa-loading-button :loading="loading">{{ $t('table.actions.update') }}</v-spa-loading-button>
                    </div>
                </form>
            </v-spa-mixed-modal>
        </portal>
    </div>
</template>

<script>

import mixin from '~base/components/Professionals/Visibility/Informations/visual-mixin';

export default {
    name: "UpdateVisual",

    mixins: [mixin],

    props: {
        update_visual_key: {
            type: String,
        },
        width: {
            type: Number,
        },
        height: {
            type: Number,
        },
        crop_width: {
            type: Number,
        },
        crop_height: {
            type: Number,
        },
        aspect_ratio: {
            type: Number,
        },
        portal: {
            type: String,
            default() {
                return 'modals';
            }
        },

        image_class: {
            type: String,
            default() {
                return 'w-full';
            }
        },

        text_position: {
            type: String,
            default() {
                return 'right-0 bottom-0 w-full';
            }
        }
    },

    methods: {
        async update()
        {
            await this.updateVisual(this.update_visual_key, this.$refs['crop-'+this.update_visual_key].getCroppedResult())
            this.$modal.hide('modal-update-visual-' + this.update_visual_key)
        },
    },
}
</script>
