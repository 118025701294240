<template>
    <div class="stars" :class="{'stars--small': small}">
        <div class="star" :class="`star--`+star" v-for="star in get()"></div>
    </div>
</template>

<script type="text/javascript">
    export default {
        props: {
            max: {
                type: Number,
                default: 5
            },
            value: {
                type: Number,
                default: 2.5
            },
            small: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            get() {
                return [
                    ...Array(this.full()).fill('full'),
                    ...Array(this.half()).fill('half'),
                    ...Array(this.empty()).fill('empty')
                ];
            },
            full() {
                return parseInt(this.value);
            },
            half()
            {
                let parts =  (this.value + "").split(".");
                if ( parts[1] ) {
                    return 1;
                }
                return 0;
            },
            empty()
            {
                if ( this.value == this.max ) {
                    return 0;
                }
                return this.max - ( this.full() + this.half() );
            }
        }
    }
</script>