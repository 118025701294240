<template>
    <layout>
        <template slot="content">
            <div class="w-full mx-auto max-w-800" v-if="loading">
                <v-spa-panel panel-height="auto" :loading="loading"></v-spa-panel>
            </div>
            <div class="w-full mx-auto max-w-500" v-else>
                <v-spa-layout-title>
                    {{ $t('base.index.title') }}
                </v-spa-layout-title>
                <div
                    class="flex items-center p-6 mb-4 bg-white shadow cursor-pointer hover:shadow-md"
                    v-for="professional in rows"
                    :key="professional.id"
                    @click.prevent="selectProfessional(professional)"
                >
                    <div v-if="professional.logo" class="w-16 h-16 mr-4 bg-gray-400 bg-center bg-no-repeat bg-contain rounded" :style="{'background-image': `url(${professional.logo})`}"></div>
                    <span class="text-gray-800">
                        {{ professional.company }}
                        <span class="block text-sm font-thin text-gray-500" v-if="professional.unread_requests_count">
                            {{ professional.unread_requests_count }} message(s) non lu(s)
                        </span>
                    </span>
                    <div class="flex-auto"></div>
                    <i class="text-2xl text-gray-600 fas fa-arrow-circle-right"></i>
                </div>
            </div>
        </template>
    </layout>
</template>

<script>
    import Layout from '~base/components/Layout';
    import mixin from '~spa/components/_Table/index-mixin.js';

    export default {
        components: {
            'layout': Layout,
        },

        mixins: [mixin],

        methods: {
            selectProfessional(professional)
            {
                this.$store.commit('setProfessional', professional);
                this.$store.state.layout.sidebar = true;
                this.$router.push({name: 'professional.inbox.base', params: {professional: professional.id}});
            }
        },

        created() {
            this.$store.state.layout.sidebar = false;

            if ( this.$store.state.user.default_professional ) {
                return this.selectProfessional(this.$store.state.user.default_professional);
            }

            this.$store.dispatch('getProfessionals', this.$store.state.user.id)
                .then(professionals => {
                    this.rows = professionals;

                    if ( this.rows.length == 1 ) {
                        return this.selectProfessional(this.rows[0]);
                    }
                    
                    this.loading = false;
                });
        }
    }
</script>