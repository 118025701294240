<template>
    <div>
        <v-spa-table-loader v-if="loading"></v-spa-table-loader>

        <form @submit.prevent="$emit('submitted')" v-else>
            <div>
                <v-spa-input id="form_title" :form="form" input-name="title"></v-spa-input>
                <v-spa-textarea id="form_answer" :form="form" input-name="answer"></v-spa-textarea>
            </div>
            <div class="mt-6 flex flex-row-reverse">
                <v-spa-loading-button :loading="form.is_loading">{{ button }}</v-spa-loading-button>
            </div>
        </form>

    </div>
</template>

<script>
    import Form from '~spa/classes/Form';

    export default {

        data() {
            return {
                loading: false,
                form: new Form({
                    title: '',
                    answer: '',
                    order: 1
                })
            }
        },

        props: ['answer', 'button'],

        mounted() {
            this.$emit('ready');
        }
    }
</script>
