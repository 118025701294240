const actions = {
    storeFacebookPost({ dispatch, state }, { pageId, data: { message, photos } })
    {
        const { id: professionalId } = state.professional;
        return dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/facebook-pages/${pageId}/feed`,
            data: {
                message,
                photos
            },
            method: 'POST'
        });
    }
}

export default actions;