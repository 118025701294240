<template>
    <li class="flex w-full py-4 border-b border-gray-200">
        <div class="flex-auto -mx-2 items-center hidden md:flex">
            <div class="px-2 flex flex-auto flex-col items-center">
                <span>{{ date }}</span>
                <span class="text-sm">{{ time }}</span>
            </div>
            <div class="px-2 flex-auto flex flex-col items-center">
                <div class="flex -mx-1 items-center">
                    <figure class="px-1">
                        <img src="/icons/small/arrow-up-right.svg" alt="calling from" class="h-4 w-4">
                    </figure>
                    <span class="px-1" :class="{ 'font-bold': isMissed }">{{ from }}</span>
                </div>
                <div class="flex -mx-1 items-center">
                    <figure class="px-1">
                        <img :src="toIcon" alt="calling to" class="h-4 w-4">
                    </figure>
                    <span class="px-1">{{ to }}</span>
                </div>
            </div>
            <span class="px-2 text-center flex-auto">{{ duration }}</span>
            <div class="px-2 flex-auto">
                <call-type :isMissed="isMissed" />
            </div>
        </div>
        <!-- responsive -->
        <div class="flex-auto flex md:hidden -mx-2 items-center">
            <div class="px-2 flex-auto flex flex-col items-center">
                <call-type :isMissed="isMissed" />
                <span class="text-xs text-gray-600">{{ date }} {{ time }}</span>
            </div>
            <div class="px-2 flex-auto flex flex-col items-center">
                <div class="flex -mx-1 items-center">
                    <figure class="px-1">
                        <img src="/icons/small/arrow-up-right.svg" alt="calling from" class="h-4 w-4">
                    </figure>
                    <span class="px-1 text-sm" :class="{ 'font-bold': isMissed }">{{ from }}</span>
                </div>
                <div class="flex -mx-1 items-center">
                    <figure class="px-1">
                        <img :src="toIcon" alt="calling to" class="h-4 w-4">
                    </figure>
                    <span class="px-1 text-sm">{{ to }}</span>
                </div>
            </div>
        </div>

    </li>
</template>

<script>
import moment from "moment"
import CallType from "~base/components/Professionals/Voip/Calls/Call/Type"
export default {
    props: {
        call: {
            type: Object,
            required: true
        }
    },
    components: {
        CallType
    },
    methods: {
        formatNumber(number)
        {
            const parts = `+${number.slice(2)}`.match(/.{1,3}/g);
            return parts.join(' ');
        }
    },
    computed: {
        momentCalledAt()
        {
            const { called_at } = this.call;
            return moment.utc(called_at);
        },
        date()
        {
            return this.momentCalledAt.format('D MMM');
        },
        time()
        {
            return this.momentCalledAt.format('HH:mm');
        },
        isAnonymous()
        {
            const { from } = this.call;
            return from.includes('anonymous');
        },
        isMissed()
        {
            const { duration } = this.call;
            return duration === 0;
        },
        from()
        {
            const { from } = this.call;
            return this.isAnonymous
                ? this.$t('voip.calls.anonymous')
                : this.formatNumber(from);
        },
        to()
        {
            const { to } = this.call;
            return this.formatNumber(to);
        },
        toIcon()
        {
            return `/icons/small/arrow-down-left-${this.isMissed ? 'red' : 'green'}.svg`
        },
        duration()
        {
            const { duration } = this.call;
            const momentDuration = moment.duration(duration, 'seconds');
            const { minutes, seconds } = momentDuration._data;
            const hasMinutes = minutes > 0;
            return hasMinutes
                ? `${minutes} min`
                : `${seconds} sec`
        }
    }
}
</script>