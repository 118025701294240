<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('visibility.informations.title') }}</template>

        <template v-slot:tabs>
            <v-spa-layout-page-tab :tab="{name: 'professional.visibility.informations.edit', label: $t('visibility.informations.links.informations')}"></v-spa-layout-page-tab>
            <v-spa-layout-page-tab :tab="{name: 'professional.visibility.notifications.edit', label: $t('visibility.informations.links.notifications')}"></v-spa-layout-page-tab>
        </template>

        <template v-slot:content>
            <slot name="informations-content"></slot>
        </template>

    </v-spa-layout-page>
</template>

<script>
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';

    export default {

        components: {
            'tmp-spa-page': Page
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-informations');
        }
    }
</script>
