import InformationsEdit from '~base/components/Professionals/Visibility/Informations/Edit';
import Cover from '~base/components/Professionals/Visibility/Informations/Cover';
import Logo from '~base/components/Professionals/Visibility/Informations/Logo';
import NotificationPreferences from '~base/components/Professionals/Visibility/Informations/NotificationPreferences';
import InterventionZone from '~base/components/Professionals/Visibility/Informations/InterventionZone';
import Workfields from '~base/components/Professionals/Visibility/Informations/Workfields';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'informations',
        component: InformationsEdit,
        'name': 'professional.visibility.informations.edit',
        meta: {
            title: '{professionalname} - Informations'
        }
    }).protected().get(),

    generator.create({
        path: 'logo',
        component: Logo,
        'name': 'professional.visibility.logo.edit',
        meta: {
            title: '{professionalname} - Logo'
        }
    }).protected().get(),

    generator.create({
        path: 'cover',
        component: Cover,
        'name': 'professional.visibility.cover.edit',
        meta: {
            title: '{professionalname} - Cover'
        }
    }).protected().get(),

    generator.create({
        path: 'notifications-preferences',
        component: NotificationPreferences,
        'name': 'professional.visibility.notifications.edit',
        meta: {
            title: '{professionalname} - Notification préférences'
        }
    }).protected().get(),

    // {
    //     path: 'intervention-zone',
    //     component: InterventionZone,
    //     'name': 'professional.visibility.informations.intervention-zone',
    //     meta: {
    //         title: '{professionalname} - Intervention Zone',
    //         authentification: true
    //     },
    // },
    // {
    //     path: 'workfields',
    //     component: Workfields,
    //     'name': 'professional.visibility.informations.workfields',
    //     meta: {
    //         title: '{professionalname} - Workfields',
    //         authentification: true
    //     }
    // },
];