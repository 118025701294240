<template>
    <layout>
        <template slot="content">
            <v-spa-panel panel-height="h-auto" :loading="true"></v-spa-panel>
        </template>
    </layout>
</template>

<script>
    import Layout from '~base/components/Layout';
    export default {
        components: {
            Layout
        },
        data() {
            return {
                redirect: null,
            }
        },
        methods: {
            getRedirection()
            {
                let redirect = {name: 'homepage'};
                
                let params = window.getQueryParams(window.location.href);
                console.log('Checking for supported redirection', params);
                
                if ( params.professional && params.route && params.route == 'show' ) {
                    return {name: 'professional.visibility.base', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'informations.edit' ) {
                    return {name: 'professional.visibility.informations.edit', params: {professional: params.professional}};
                }

                if ( params.professional && params.route && params.route == 'visuals.edit' ) {
                    return {name: 'professional.visibility.informations.edit', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'statistics.index' ) {
                    return {name: 'professional.visibility.statistics.show', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'facebook-pages.edit' ) {
                    return {name: 'professional.visibility.facebook-pages.edit', params: {professional: params.professional}};
                }

                if ( params.professional && params.route && params.route == 'projects.index' ) {
                    return {name: 'professional.visibility.projects.index', params: {professional: params.professional}};
                }

                if ( params.professional && params.route && params.route == 'projects.edit' && params.project ) {
                    return {name: 'professional.visibility.projects.edit', params: {professional: params.professional, project: params.project}};
                }

                if ( params.professional && params.route && params.route == 'ratings.index' ) {
                    return {name: 'professional.visibility.ratings.index', params: {professional: params.professional}};
                }

                if ( params.professional && params.route && params.route == 'ratings.request' ) {
                    return {name: 'professional.visibility.ratings.collect', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'requests.index' ) {
                    return {name: 'professional.inbox.requests.index', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'requests.archived' ) {
                    return {name: 'professional.inbox.requests.index', params: {professional: params.professional}};
                }
                
                if ( params.professional && params.route && params.route == 'requests.show' && params.request ) {
                    return {name: 'professional.inbox.requests.show', params: {professional: params.professional, request: params.request}};
                }

                if ( params.professional && params.route && params.route == 'print.request' && params.request ) {
                    return {name: 'professional.inbox.requests.print', params: {professional: params.professional, request: params.request}};
                }

                return redirect;
            }
        },
        created() {
            let redirect = this.getRedirection();

            if ( redirect ) {
                console.log('Redirect found:', redirect);
                return this.$router.push(redirect);
            }

            console.log('Redirect not found.');
            return this.$router.push({name: 'homepage'});
        }
    }
</script>