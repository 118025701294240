<template>
    <tr class="cursor-pointer" @click="$emit('clicked')">
        <td :class="$spa.css.table.td_first" class="text-sm font-semibold">
            {{ rating.user.name }}
            <p class="mt-1 text-xs text-red-600" v-if="rating.is_reported">
                <i class="mr-1 fas fa-exclamation-circle" />
                {{ $t('visibility.ratings.card.reported') }}
            </p>
        </td>
        <td :class="$spa.css.table.td">
            <div class="flex w-max">
                <stars :value="rating.value"></stars>
            </div>
        </td>
        <td :class="$spa.css.table.td">
            <p class="text-sm text-slate-600">
                {{ previewText | truncate('150') }}
            </p>
            <p class="mt-2 text-xs font-thin text-slate-400">Source: TrustUp.be</p>
        </td>
        <td :class="$spa.css.table.td_last">
            <div class="flex justify-end">
                <template v-if="rating.answer">
                    <div class="flex items-center text-green-600">
                        <i class="mr-2 text-sm text-green-600 far fa-check-circle" />
                        <span>{{ $t('visibility.ratings.card.status.answered') }}</span>
                    </div>
                </template>
                <template v-else>
                    <v-spa-loading-button :class-name="`${$spa.css.button.style.gray_xs} flex items-center hover:bg-gray-300`" :small="true" @clicked="$emit('clicked')">
                        <i class="mr-2 text-sm far fa-pencil" />
                        <span>{{ $t('visibility.ratings.card.status.not_answered') }}</span>
                    </v-spa-loading-button>
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
    import StarsVue from '~base/components/_Partials/Stars.vue';

    export default {
        components: {
            'stars': StarsVue,
        },
        computed: {
            previewText() {
                return this.rating.text.replace('<br/>', '')
            }
        },
        props: {
            rating: {
                type: Object,
            }
        },
    }
</script>
