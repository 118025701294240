export default {

    getProjects(context, {professionalId, page})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/projects?page=${page}`
        });
    },

    getProject(context, {professionalId, projectId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/projects/${projectId}`,
            key: 'project'
        });
    },

    deleteProject(context, {professionalId, projectId, array = null})
    {
        return context.dispatch('deleteData', {
            url: `${window.api_url}/me/professionals/${professionalId}/projects/${projectId}`,
            method: 'DELETE',
            array: array,
            itemToDelete: projectId
        });
    },

    restoreProject(context, {professionalId, projectId})
    {
        return context.dispatch('ping', {
            url: `${window.api_url}/me/professionals/${professionalId}/projects/${projectId}/restore`,
            method: 'POST'
        });
    }

}