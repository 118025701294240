const actions = {
    getVoipCalls({ dispatch }, { professionalId, page = 1, perPage = 30 })
    {
        return dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/voip/calls`,
            urlParams: {
                page,
                perPage
            }
        });
    }
}

export default actions;