<template>
    <settings-base>
        <template v-slot:settings-content>
            <v-spa-panel :loading="true" v-if="loading"></v-spa-panel>
            <v-spa-panel v-if="! loading && rows.length == 0">
                <p class="mb-6 text-lg font-thin text-center text-gray-700">{{ $t('inbox.settings.quick_answers.index.empty.text') }}</p>
                <div class="flex justify-center w-full">
                    <v-spa-loading-button @clicked="$modal.show('quick-answers-create')">
                        {{ $t('inbox.settings.quick_answers.index.empty.button') }}
                    </v-spa-loading-button>
                </div>
            </v-spa-panel>
            <v-spa-panel v-if="! loading && rows.length > 0" class="!border-0 !p-0">
                <table class="w-full">
                    <thead>
                    <tr>
                        <th :class="$spa.css.table.th_first">
                            {{ $t('inbox.settings.quick_answers.index.table.headers.title') }}
                        </th>
                        <th :class="$spa.css.table.th">
                            {{ $t('inbox.settings.quick_answers.index.table.headers.content') }}
                        </th>
                        <th :class="$spa.css.table.th_last">

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="answer in rows">
                            <tr class="relative" :key="answer.id">
                                <td :class="$spa.css.table.td_first" class="text-sm font-bold">{{ answer.title }}</td>
                                <td :class="$spa.css.table.td" class="text-sm">{{ answer.answer | truncate('300') }}</td>
                                <td :class="$spa.css.table.td_last">
                                    <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`request-card-${answer.id}`">
                                        <template v-slot:default="slotProps">
                                            <div class="min-h-[40px]">
                                                <div class="flex items-center justify-end h-full px-4 py-2 text-gray-600 cursor-pointer hover:text-black" @click.stop.prevent="slotProps.toggle()">
                                                    <i class="text-sm group-hover:block fas fa-ellipsis-h" />
                                                </div>
                                            </div>
                                        </template>

                                        <div class="relative w-auto" :container="$spa.css.submenu.container.default" slot="submenu">
                                            <a href="#" @click.prevent="editAnswer(answer)" :class="$spa.css.contextmenu.link">
                                                <i :class="`${$spa.css.contextmenu.icon} fas fa-pencil`" />
                                                {{ $t('table.actions.edit') }}
                                            </a>
                                            <v-spa-loader v-if="deleting.includes(answer.id)"></v-spa-loader>
                                            <a href="#" :class="$spa.css.contextmenu.link"
                                               @click.prevent="deleteAnswer(answer)" v-else>
                                                <i :class="`${$spa.css.contextmenu.icon} fas fa-trash`" />
                                                {{ $t('table.actions.delete') }}
                                            </a>
                                        </div>
                                    </v-spa-submenu-container>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </v-spa-panel>
            <div class="flex justify-center mt-6" v-if="! loading && rows.length > 0">
                <v-spa-loading-button @clicked="$modal.show('quick-answers-create')">
                    {{ $t('inbox.settings.quick_answers.index.create_button') }}
                </v-spa-loading-button>
            </div>

            <portal to="modals">
                <v-spa-right-panel-modal name="quick-answers-create" right-panel-width="550px" right-panel-width-xl="550px">
                    <h4 class="text-2xl mb-6">{{ $t('inbox.settings.quick_answers.index.modals.create.title') }}</h4>
                    <create></create>
                </v-spa-right-panel-modal>
            </portal>

            <portal to="modals-sub">
                <v-spa-right-panel-modal name="quick-answers-edit" right-panel-width="550px" right-panel-width-xl="550px">
                    <h4 class="text-2xl mb-6">{{ $t('inbox.settings.quick_answers.index.modals.edit.title') }}</h4>
                    <edit :answer-id="selectedAnswerId"></edit>
                </v-spa-right-panel-modal>
            </portal>

        </template>
    </settings-base>
</template>

<script>
    import Base from './../Base';
    import mixin from '~spa/components/_Table/index-mixin.js';
    import Form from "./Form";
    import Create from "./Create";
    import Edit from "./Edit";

    export default {
        components: {
            Edit,
            Create,
            'settings-base': Base,
            'answer-form': Form,
        },

        mixins: [mixin],

        data() {
            return {
                deleting: [],
                selectedAnswerId: null,
            }
        },

        methods: {
            editAnswer(answer) {
                this.selectedAnswerId = answer.id
                this.$modal.show('quick-answers-edit')
            },

            deleteAnswer(answer)
            {
                this.deleting.push(answer.id);
                this.$store.dispatch('deleteQuickAnswer', {professionalId: this.$store.state.professional.id, answerId: answer.id})
                    .then(() => {
                        this.rows = this.rows.filter(a => a.id !== answer.id);
                        this.deleting = this.deleting.filter(id => id !== answer.id);
                        window.Toasteo.success(this.$t('inbox.settings.quick_answers.index.modals.edit.deleted'));
                    });
            },

            fetchAnswers() {
                this.loading = true;

                this.$store.dispatch('getQuickAnswers', {professionalId: this.$store.state.professional.id})
                    .then(answers => {
                        this.rows = answers;
                        if ( this.rows.length > 0 ) {
                            this.loading = false;
                            return;
                        }

                        this.$store.dispatch('makeDefaultQuickAnswers', {professionalId: this.$store.state.professional.id})
                            .then(response => {
                                this.rows = response.data.quick_answers;
                                this.loading = false;
                            })
                            .catch(() => this.loading = false);
                    });
            }
        },

        created() {
            this.$bus.$on('quick-answers:created', this.fetchAnswers)
            this.$bus.$on('quick-answers:updated', this.fetchAnswers)
            this.fetchAnswers()
        },

        destroyed() {
            this.$bus.$off('quick-answers:created', this.fetchAnswers)
            this.$bus.$off('quick-answers:updated', this.fetchAnswers)
        }
    }
</script>
