<template>
    <div class="h-full">
        <v-spa-table-loader v-if="loading"></v-spa-table-loader>
        <form @submit.prevent="submit" v-else class="h-full flex flex-col xl:flex-row mt-8 xl:mt-0">

            <div class="h-full w-full xl:w-[350px] flex flex-col xl:flex-row xl:space-x-8 xl:space-x-0 xl:flex-col xl:space-y-8 xl:border-r xl:border-slate-200">
                <!-- INFOS SECTION -->
                <div class="p-2 md:p-4 xl:p-8 xl:border-b xl:border-slate-200">
                    <template>
                        <v-spa-input :class-name="$spa.css.form.input_full_width" id="form_title" :form="form" input-name="title"></v-spa-input>
                        <v-spa-textarea :class-name="$spa.css.form.input_full_width" id="form_description" :form="form" input-name="description"></v-spa-textarea>
                    </template>
                </div>

                <!-- SHARE SECTION -->
                <div class="p-2 md:p-4 xl:p-8">
                    <p class="mb-4">{{ $t('visibility.projects.form.tabs.share') }}</p>
                    <template>
                        <v-spa-element id="form_facebook_pages" :form="form" input-name="facebook_pages">
                            <form-facebook-page :project="project" :form="form" :facebook-page="facebookPage" :last="index+1 == facebookPages.length" v-for="(facebookPage, index) in facebookPages" :key="facebookPage.page_id"></form-facebook-page>
                        </v-spa-element>
                        <v-spa-element id="form_website_albums" :form="form" input-name="website_albums" v-if="hasAlbums">
                            <template v-for="site in sites">
                                <form-album :project="project" :form="form" :album="album" :last="index+1 == site.albums.length" v-for="(album, index) in site.albums" :key="album.id"></form-album>
                            </template>
                        </v-spa-element>
                    </template>
                </div>
            </div>

            <!-- IMAGES SECTION -->
            <div class="p-2 md:p-4 xl:p-8 flex-grow">
                <div class="w-full">

                    <template>
                        <form-images :form="form" :project="project"></form-images>
                    </template>
                </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <portal to="page-title-button">
                <v-spa-loading-button :class-name="$spa.css.button.style.delete_link + ' text-sm'" @clicked.prevent="deleteProject" ref="deleteProjectButton" v-if="project">{{ $t('visibility.projects.form.delete_button') }}</v-spa-loading-button>
                <v-spa-loading-button :loading="form.is_loading" :prevent-default="true" @clicked="submit">{{ button }}</v-spa-loading-button>
            </portal>
        </form>
    </div>
</template>

<script>

    import Form from '~spa/classes/Form';
    import FormFacebookPage from './Form/FacebookPage';
    import FormAlbum from './Form/Album';
    import FormImages from './Form/Images';

    export default {

        components: {
            'form-images': FormImages,
            'form-facebook-page': FormFacebookPage,
            'form-album': FormAlbum
        },

        data() {
            return {
                loading: true,
                sites: [],
                facebookPages: [],
                sites: [],
                step: 'content',
                successMessage: '',
                form: new Form({
                    title: '',
                    description: '',
                    images: [],
                    website_albums: [],
                    facebook_pages: []
                })
            }
        },

        computed: {
            hasAlbums() {
                for ( let i = 0; i < this.sites.length; i++ ) {
                    if ( this.sites[i].albums.length > 0 ) {
                        return true;
                    }
                }
                return false;
            }
        },

        props: ['project', 'button'],

        methods: {
            deleteProject()
            {
                if ( ! window.confirm("Êtes-vous sur de vouloir supprimer ce projet ?") ) {
                    return;
                }

                this.$refs.deleteProjectButton.load();

                this.$store.dispatch('deleteProject', {professionalId: this.$store.state.professional.id, projectId: this.project.id})
                    .then(() => {
                        window.Toasteo.success(this.$t('visibility.projects.deleted'));
                        this.$router.push({name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}});
                    })
            },

            getFacebookPages()
            {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getFacebookPages', {professionalId: this.$store.state.professional.id})
                        .then((facebookPages) => {
                            this.facebookPages = facebookPages;
                            resolve();
                        });
                });
            },

            getWebsiteAlbums()
            {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getWebsiteAlbums', {professionalId: this.$store.state.professional.id})
                        .then((sites) => {
                            this.sites = sites;
                            resolve();
                        });
                });
            },

            success(message, redirect)
            {
                window.Toasteo.success(this.$t('visibility.projects.saved'));
                this.$router.push(redirect)
            },

            submit()
            {
                this.$emit('submitted')
            }
        },

        created() {
            Promise.all([
                this.getFacebookPages(),
                this.getWebsiteAlbums()
            ]).then(() => {
                this.$emit('ready');
            })
        }
    }
</script>
