
export default {
    professional: null,
    google: null,
    section: null,
    apps: null,
    layout: {
        header: true,
        sidebar: true,
        container: true,
        intercom: true
    },
    redirectTo: null,
    openedModals: [],
    googleTranslateIsActive: false
};