import ProjectsIndex from '~base/components/Professionals/Visibility/Projects/Index';
import ProjectsCreate from '~base/components/Professionals/Visibility/Projects/Create';
import ProjectsShow from '~base/components/Professionals/Visibility/Projects/Show';
import ProjectsEdit from '~base/components/Professionals/Visibility/Projects/Edit';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'projects',
        component: ProjectsIndex,
        'name': 'professional.visibility.projects.index',
        meta: {
            title: '{professionalname} - Projects'
        }
    }).protected().get(),
    
    generator.create({
        path: 'projects/create',
        component: ProjectsCreate,
        'name': 'professional.visibility.projects.create',
        meta: {
            title: '{professionalname} - Create project'
        }
    }).protected().get(),
    
    generator.create({
        path: 'projects/:project',
        component: ProjectsShow,
        'name': 'professional.visibility.projects.show',
        meta: {
            title: '{professionalname} - Project'
        }
    }).protected().get(),
    
    generator.create({
        path: 'projects/:project/edit',
        component: ProjectsEdit,
        'name': 'professional.visibility.projects.edit',
        meta: {
            title: '{professionalname} - Edit project'
        }
    }).protected().get(),
    
]