<template>
    <v-spa-layout-page :contentPadding="false">
        <template v-slot:title>{{ $t('visibility.projects.create.title') }}</template>

        <template v-slot:content>
            <project-form ref="form" @ready="$refs.form.loading = false" @submitted="store" :button="$t('table.actions.save')"></project-form>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import ProjectForm from './Form';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';

    export default {
        components: {
            'project-form': ProjectForm,
            'tmp-spa-page': Page
        },

        methods: {
            store()
            {
                this.$refs.form.form.post( `${window.api_url}/me/professionals/${this.$store.state.professional.id}/projects` )
                    .then((response) => {
                        this.$refs.form.success(this.$t('visibility.projects.create.success'), {name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}});
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$refs.form.form.stop();
                    })
            }
        },
        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-projects');

            this.$bus.$on('ctrl-s', (resource) => {
                this.store();
            });
        },
        beforeDestroy() {
            this.$bus.$off('ctrl-s');
        }
    }
</script>
