import StatisticsShow from '~base/components/Professionals/Visibility/Statistics/Show';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'statistics',
        component: StatisticsShow,
        name: 'professional.visibility.statistics.show',
        meta: {
            title: '{professionalname} - Statistics'
        }
    }).protected().get()
];