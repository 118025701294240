<template>
    <div class="h-full requests-print">
        <v-spa-panel :loading="loading" v-if="loading"></v-spa-panel>
        <template v-else>
            <div class="mx-auto max-w-800">
                <h1 class="py-4 mb-4 text-center bg-gray-100 rounded">
                    <span class="text-lg" v-if="model.model">
                        <span v-if="model.model.workfield">
                            {{ model.model.workfield.title }} -
                        </span>
                        {{ model.user.full_name }}
                    </span>
                    <span class="block text-sm" v-if="model.model">
                        Demande de devis #{{ model.model.id }}
                    </span>
                </h1>
                <v-author :model="model"></v-author>
                <quote-address :model="model" v-if="model.model"></quote-address>
                <div class="p-4 mx-auto mb-8 bg-white border-t-4 rounded-lg shadow max-w-500 border-trustup" v-if="model.type == 'guest-quote' || model.type == 'website-message'">
                    <div class="flex items-center w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 w-8 h-8 mr-4 text-gray-600 devfont devfont-circle-info"><circle cx="12" cy="12" r="9"></circle><path d="M12 12v4"></path><path d="M12 8h.01"></path></svg>
                        <p class="font-thin text-gray-700" v-if="model.type == 'guest-quote'">
                            {{ $t('inbox.requests.show.panels.guest_quote.text') }}
                        </p>
                        <p class="font-thin text-gray-700" v-if="model.type == 'website-message'">
                            {{ $t('inbox.requests.show.panels.website_message.text') }}
                        </p>
                    </div>
                </div>

                <website-form-submission v-if="model.source == 'website-form-submission'" :model="model"></website-form-submission>

                <quote-description v-if="model.source == 'quote'" :model="model"></quote-description>

                <conversation-chat :messages="model.chat.messages" v-if="model.chat && model.chat.messages.length > 0"></conversation-chat>
            </div>
        </template>
    </div>
</template>

<script>
    import mixin from '~spa/components/_Table/show-mixin.js';
    import QuoteDescription from './Show/QuoteDescription.vue';
    import Author from './Show/Author.vue';
    import QuoteAddressVue from './Show/QuoteAddress.vue';
    import Chat from './_Partials/Chat.vue';
    import WebsiteFormSubmission from './Show/WebsiteFormSubmission.vue';

    export default {
        components: {
            'v-author': Author,
            'conversation-chat': Chat,
            'quote-description': QuoteDescription,
            'quote-address': QuoteAddressVue,
            'website-form-submission': WebsiteFormSubmission
        },

        mixins: [mixin],
        methods: {
            print()
            {
                window.print();
                window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }
            }
        },

        created() {
            this.$store.dispatch('getRequest', {professionalId: this.$store.state.professional.id, requestId: this.$route.params.request})
                .then(request => {
                    this.model = request;
                    if ( ! this.model.is_read ) {
                        this.$store.state.professional.unread_requests_count--;
                    }

                    this.$store.dispatch('markRequestAsRead', {professionalId: this.$store.state.professional.id, requestId: this.model.id});
                    this.loading = false;
                    this.$nextTick(() =>
                        setTimeout(() => this.print(), 1000)
                    );
                });
        }
    }
</script>
