<template>
    <div>
        <chat-message :message="message" v-for="(message, index) in messages" :should-display-date="shouldDisplayDate(message, index)" :key="message.id"></chat-message>
    </div>
</template>

<script>
    import MessageVue from './Message.vue';
    export default {
        components: {
            'chat-message': MessageVue
        },
        props: ['messages'],
        methods: {
            shouldDisplayDate(message, index)
            {
                if ( index == 0 ) {
                    return true;
                }

                return ! moment(this.messages[index - 1].created_at).isSame(message.created_at, 'day');
            }
        }
    }
</script>
