<template>
    <div>
        <v-spa-layout-page>
            <template v-slot:title>{{ title }}</template>

            <div slot="title-button" class="relative z-20 flex items-center">
                <v-spa-submenu-container position="right-0" ref="actionsSubmenu">
                    <template v-slot:default="slotProps">
                        <button class="relative px-4 py-2 text-gray-600 bg-white rounded hover:text-gray-800" :class="$spa.css.button.color.white_border" @click="slotProps.toggle()">
                            <i class="mr-1 fas fa-filter"></i>
                            {{ $t('inbox.requests.list.filters.label') }}
                        </button>
                    </template>
                    <div class="w-[250px]" slot="submenu">
                        <div class="px-4 py-2">
                            <span class="block mb-1 text-sm text-gray-500">{{ $t('inbox.requests.list.filters.order.label') }}</span>
                            <select class="w-full p-2 pr-8 text-sm font-light border border-gray-400 outline-none cursor-pointer select-with-chevron hover:bg-gray-100 text-gray-8" @change="getRequests()" v-model="filters.order">
                                <option value="unread">{{ $t('inbox.requests.list.filters.order.unread') }}</option>
                                <option value="latest">{{ $t('inbox.requests.list.filters.order.latest') }}</option>
                                <option value="oldest">{{ $t('inbox.requests.list.filters.order.oldest') }}</option>
                            </select>
                        </div>
                        <div class="px-4 py-2">
                            <span class="block mb-1 text-sm text-gray-500">{{ $t('inbox.requests.list.filters.source.label') }}</span>
                            <select class="w-full p-2 pr-8 text-sm font-light border border-gray-400 outline-none cursor-pointer select-with-chevron hover:bg-gray-100 text-gray-8" @change="getRequests()" v-model="filters.media">
                                <option value="all">{{ $t('inbox.requests.list.filters.source.all') }}</option>
                                <option value="trustup">{{ $t('inbox.requests.list.filters.source.trustup') }}</option>
                                <option value="website">{{ $t('inbox.requests.list.filters.source.website') }}</option>
                            </select>
                        </div>
                    </div>
                </v-spa-submenu-container>
            </div>

            <template v-slot:content>
                <div class="flex items-stretch w-full mb-8 -mx-2 overflow-x-auto">
                    <div
                        v-for="statusGroup in statusGroups"
                        :key="statusGroup.group"
                        class="px-4 py-2 mx-2 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100"
                        :class="{'border-primary bg-gray-100': statusGroup.group == filters['status-group']}"
                        @click.prevent="setStatusGroupFilter(statusGroup.group)"
                    >
                        <span class="block mb-0 text-2xl font-bold" :class="[statusGroup.group == filters['status-group'] ? 'text-primary' : 'text-gray-800']">{{ statusGroup.count }}</span>
                        <span class="block -mt-1 whitespace-nowrap" :class="[statusGroup.group == filters['status-group'] ? 'text-primary' : 'text-gray-600']">{{ $t(`inbox.requests.status_groups.${statusGroup.group}`) }}</span>
                    </div>
                </div>

                <v-spa-panel extra-class-name="overflow-hidden" class="!border-0 !p-0" :loading="loading" :empty="showEmptyMessage">
                    <div class="flex flex-col space-y-2" v-if="!showEmptyMessage">
                        <request-card :request="request" v-for="request in rows" :key="request.id"></request-card>
                    </div>
                    <div v-else>
                        <div class="mx-auto py-12" v-if="!rows.length">
                            <div class="w-[400px] mx-auto">
                                <v-spa-lottie-empty-2></v-spa-lottie-empty-2>
                            </div>
                            <p class="my-12 text-lg font-thin text-center text-gray-700">{{ emptyMessageText }}</p>
                        </div>
                    </div>
                </v-spa-panel>

                <div class="flex justify-center pb-8 mt-6" v-if="pagination && pagination.last_page !== 1">
                    <paginate
                        v-model="pagination.current_page"
                        :no-li-surround="true"
                        :page-count="pagination.last_page"
                        :click-handler="getRequests"
                        :prev-text="$t('pagination.previous')"
                        :prev-link-class="'prev'"
                        :next-text="$t('pagination.next')"
                        :next-link-class="'next'"
                        :container-class="'pagination'"
                        :active-class="'active'"
                        ref="paginate"
                    ></paginate>
                </div>
            </template>
        </v-spa-layout-page>
    </div>
</template>

<script>

    import mixin from '~spa/components/_Table/index-mixin.js';
    import CardVue from './Card.vue';
    import Paginate from 'vuejs-paginate';
    import Page from "~base/components/_Layout/TmpSpa/Layout/Page";

    export default {
        components: {
            Paginate,
            'request-card': CardVue,
            'tmp-spa-page': Page,
        },
        mixins: [mixin],
        props: ['title', 'emptyMessage', 'emptyFiltersMessage', 'action'],
        data() {
            return {
                error: false,
                pagination: null,
                route: null,
                statusGroups: [],
                filters: {
                    'status-group': null,
                    'page': 1,
                    'order': 'unread',
                    'media': 'all'
                }
            }
        },
        computed: {
            defaultFilters() {
                return this.filters.order == 'unread' && this.filters.media == 'all';
            },
            emptyMessageText() {
                return this.defaultFilters ? this.emptyMessage : this.emptyFiltersMessage;
            },
            showEmptyMessage() {
                if ( this.loading ) {
                    return false;
                }
                if ( this.error ) {
                    return true;
                }

                return this.rows == null || this.rows.length == 0;
            }
        },
        methods: {
            setStatusGroupFilter(group)
            {
                this.filters['status-group'] = group;
                this.getRequests();
            },

            getRequests(page = 1)
            {
                this.$refs.actionsSubmenu.hide();
                this.filters.page = page;
                this.error = false;
                this.loading = true;
                this.$store.dispatch(this.action, {professionalId: this.$store.state.professional.id, filters: this.filters})
                    .then(data => {
                        this.rows = data.requests;
                        this.pagination = data.pagination;
                        this.loading = false;
                        if ( this.route == this.$route.name ) {
                            this.$router.replace({name: this.$route.name, params: {professional: this.$store.state.professional.id}, query: this.filters}).catch(()=>{});
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.error = true;
                        this.loading = false;
                    });
            }
        },
        created() {
            this.route = this.$route.name;
            this.$store.dispatch('getStatusGroups', {professionalId: this.$store.state.professional.id})
                .then((data) => {
                    this.statusGroups = data;
                    this.filters['status-group'] = this.statusGroups[0].group;
                    this.getRequests(this.$route.query.page);
                });
        }
    }
</script>
