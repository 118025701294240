<template>
    <div id="app" class="relative min-h-screen notranslate">
        <div class="absolute inset-0 z-50 p-16" v-if="$store.state.loadingApplication">
            <v-spa-loader></v-spa-loader>
            <p class="font-thin text-center text-gray-700">Loading application...</p>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>    
    export default {
        name: 'app',

        watch: {
            '$route': {
                deep: true,
                handler: function (page) {
                    if ( typeof window.Intercom === 'function' ) {
                        window.Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)});
                    }
                    
                    if ( this.$store.state.professional ) {
                        this.$store.dispatch('setVariableInTitle', {variable: 'professionalname', value: this.$store.state.professional.company});
                    }
                    if ( page.name.startsWith('professional.inbox.') ) {
                        this.$store.commit('setSection', 'inbox');
                    } else if ( page.name.startsWith('professional.visibility.') ) {
                        this.$store.commit('setSection', 'visibility');
                    } else {
                        this.$store.commit('setSection', null);
                    }
                }
            }
        }
    }
</script>