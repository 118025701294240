<template>
    <div class="flex flex-col">
        <template v-if="$store.state.professional">
            <!-- <div class="px-4 mb-4">
                <div class="relative">
                    <span class="relative block px-6 py-3 bg-white shadow-md cursor-pointer hover:shadow-lg transition-250 text-primary" :class="{'rounded-full': !quickActions, 'rounded-t-lg': quickActions}" @click.prevent="quickActions = ! quickActions">
                        {{ $t('layout.sidebar.quick_actions.label') }}
                        <span class="chevron-down" :class="{'active': quickActions}"></span>
                    </span>
                    <div class="absolute inset-x-0 z-10 bg-white top-full shadow-dropdown" v-if="quickActions">
                        <router-link class="block px-6 py-3 text-gray-600 transition-all hover:text-gray-800 hover:bg-gray-100 transition-250" :to="{name: 'professional.visibility.projects.create', params: {professional: $store.state.professional.id}}">
                            {{ $t('layout.sidebar.quick_actions.add_project') }}
                        </router-link>
                        <router-link class="block px-6 py-3 text-gray-600 transition-all hover:text-gray-800 hover:bg-gray-100 transition-250" :to="{name: 'professional.inbox.requests.index', params: {professional: $store.state.professional.id}}">
                            {{ $t('layout.sidebar.quick_actions.see_requests') }}
                        </router-link>
                    </div>
                </div>
            </div> -->
            <template>
                <v-spa-layout-sidebar-link section="inbox-requests" :to="{name: 'professional.inbox.requests.index', params: {professional: $store.state.professional.id}}" icon="far fa-inbox">
                    {{ $t('layout.sidebar.inbox.requests') }}
                    <div class="absolute right-0 w-5 mr-2 center-y" v-if="$store.state.professional.unread_requests_count > 0">
                        <span class="flex items-center justify-center w-4 h-4 ml-1 text-center bg-red-600 rounded-full">
                            <span class="text-xs font-semibold text-white" v-text="$store.state.professional.unread_requests_count"></span>
                        </span>
                    </div>
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link v-if="professionalCanAccessVoipCalls" section="voip-calls" :to="{name: 'professional.inbox.voip.calls.index', params: {professional: $store.state.professional.id}}" icon="far fa-phone-plus">
                    {{ $t('layout.sidebar.voip.calls') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-ratings" :to="{name: 'professional.visibility.ratings.index', params: {professional: $store.state.professional.id}}" icon="far fa-star">
                    {{ $t('layout.sidebar.visibility.ratings') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-projects" :to="{name: 'professional.visibility.projects.index', params: {professional: $store.state.professional.id}}" icon="fal fa-image">
                    {{ $t('layout.sidebar.visibility.projects') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-facebook-pages" :to="{name: 'professional.visibility.facebook-pages.edit', params: {professional: $store.state.professional.id}}" icon="fab fa-facebook-f">
                    {{ $t('layout.sidebar.visibility.facebook_pages') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-informations" :to="{name: 'professional.visibility.informations.edit', params: {professional: $store.state.professional.id}}" icon="fal fa-pager">
                    {{ $t('layout.sidebar.visibility.informations') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-statistics" :to="{name: 'professional.visibility.statistics.show', params: {professional: $store.state.professional.id}}" icon="fal fa-analytics">
                    {{ $t('layout.sidebar.visibility.statistics') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="inbox-settings" :to="{name: 'professional.inbox.settings.quick-answers.index', params: {professional: $store.state.professional.id}}" icon="far fa-cog">
                    {{ $t('layout.sidebar.inbox.settings') }}
                </v-spa-layout-sidebar-link>
            </template>
        </template>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                quickActions: false
            }
        },
        computed: {
            ...mapState(['site']),
            ...mapGetters(['professionalCanAccessVoipCalls'])
        }
    }
</script>
