<template>
    <div>
        <h4 class="text-2xl mb-6">{{ $t('visibility.facebook_pages.create') }}</h4>

        <form @submit.prevent="submit" v-if="visible.form">
            <v-spa-select v-if="havingSeveralPages" :options="pages" option-key="name" option-value="id" id="facebook-page-id" :form="form" input-name="pageId" :allow-null="false" :label="$t('form.labels.facebook_pages')" />
            <v-spa-textarea id="form_title" :form="form" input-name="message" label="Message" />
            <v-spa-image-uploader
                :form="form"
                input-name="photos"
                label="Photo"
                :resize-images="true"
                :multiple-values="false"
                @load="loadPhotos"
            />

            <div class="flex justify-end">
                <v-spa-loading-button ref="submitButton" :disabled="!canSubmit">
                    {{ $t('visibility.facebook_pages.posts.buttons.create.now') }}
                </v-spa-loading-button>
            </div>
        </form>
        <div class="flex flex-col items-center justify-center py-6 class" v-else>
            <img src="/icons/success-large.svg" class="w-48 h-48 mx-auto mb-5 text-green-600" />
            <p class="text-lg font-semibold text-center text-gray-600"> {{ $t('visibility.facebook_pages.posts.messages.create.success') }} </p>
            <div class="flex mt-8">
                <button class="relative px-4 py-3 mr-2 text-base text-gray-600 transition-all duration-300 rounded outline-none hover:text-black" @click.prevent="again">
                    {{ $t('visibility.facebook_pages.posts.buttons.create.again') }}
                </button>
                <v-spa-loading-button @clicked="showPost">
                    {{ $t('visibility.facebook_pages.posts.buttons.create.success') }}
                </v-spa-loading-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        pages: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            form: {
                message: '',
                photos: [],
                pageId: ''
            },
            post: {
                url: ''
            },
            visible: {
                form: true
            },
            loading: {
                photos: false
            }
        }
    },
    methods: {
        ...mapActions(['storeFacebookPost']),
        async submit()
        {
            if (!this.canSubmit) return;
            this.$refs.submitButton.load();
            const { message, pageId, photos } = this.form
            try {
                const { data } = await this.storeFacebookPost({
                    pageId,
                    data: {
                        message,
                        photos: photos.map(({ source }) => source)
                    }
                });
                this.post = data;
                this.hideForm();
            } catch({ response: { data } = {} }) {
                const { error: { code, type } = {} } = data;
                // Token expired
                if (code === 190 && type === "OAuthException") console.error("Token expired.")
                window.Toasteo.error(this.$t('visibility.facebook_pages.posts.messages.create.error'));
            }
            this.$refs.submitButton.stop();
        },
        resetForm()
        {
            this.form = {
                ...this.form,
                message: '',
                photos: []
            };
            return this;
        },
        resetPost()
        {
            this.post = {
                url: ''
            };
            return this;
        },
        showForm()
        {
            this.visible.form = true;
            return this;
        },
        hideForm()
        {
            this.visible.form = false;
            return this;
        },
        again()
        {
            this.resetForm()
                .resetPost()
                .showForm();
        },
        showPost()
        {
            window.open(this.post.url, '_blank');
        },
        loadPhotos(isLoading)
        {
            this.loading.photos = isLoading;
        }
    },
    computed: {
        havingSeveralPages()
        {
            return this.pages.length > 1;
        },
        havingMessageOrPhotos()
        {
            return this.form.photos.length > 0
                || this.form.message.length > 0;
        },
        canSubmit()
        {
            return this.havingMessageOrPhotos
                && this.loading.photos === false;
        }
    },
    created()
    {
        this.form.pageId = this.pages[0].id;
    }
}
</script>

<style>

</style>
