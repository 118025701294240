<template>
    <v-spa-submenu-container :position="submenuPosition" ref="actionsSubmenu">
        <template v-slot:default="slotProps">
            <button :class="badgeClass" @click="slotProps.toggle()">
                <i class="mr-2 text-xs fas fa-circle"></i>
                <span class="-mt-1 leading-none whitespace-nowrap">
                    {{ $t(`inbox.requests.status.${model.status}`) }}
                </span>
            </button>
        </template>
        <div class="w-[250px]" slot="submenu">
            <div class="px-4 py-2">
                <span class="block mb-4 text-gray-800">{{ $t('inbox.requests.show.panels.update_status.title') }}</span>
                <a class="flex items-center w-full mb-2" :class="[value == model.status ? `text-${changeStatusBubleColor(value)}` : `text-gray-600 hover:text-${changeStatusBubleColor(value)}`]" href="#" @click.prevent="updateStatus(value)" v-for="value in status" :key="value">
                    <span class="w-4 h-4 mr-2 border-2 rounded-full" :class="[`border-${changeStatusBubleColor(value)}`, model.status == value ? `bg-${changeStatusBubleColor(value)}` : '']"></span>
                    <span>{{ $t(`inbox.requests.status.${value}`) }}</span>
                </a>
            </div>
        </div>
    </v-spa-submenu-container>
</template>

<script>
    export default {
        props: {
            model: {
                required: true
            },
            submenuPosition: {
                type: String,
                default: 'right-0'
            }
        },
        data() {
            return {
                status: ['pending', 'in-progress', 'quote-sent', 'won', 'lost', 'declined']
            }
        },
        computed: {
            badgeClass() {
                let baseClass = 'h-full flex items-center relative px-4 py-2 rounded border-2 ';
                
                if ( this.model.status == 'pending' ) {
                    baseClass += 'border-primary text-primary bg-white';
                }

                if ( this.model.status == 'in-progress' ) {
                    baseClass += 'border-orange-500 text-orange-500 bg-white';
                }

                if ( this.model.status == 'quote-sent' ) {
                    baseClass += 'border-green-500 text-green-500 bg-white';
                }

                if ( this.model.status == 'won' ) {
                    baseClass += 'bg-green-600 text-white border-green-600';
                }

                if ( this.model.status == 'lost' ) {
                    baseClass += 'bg-red-600 text-white border-red-600';
                }

                if ( this.model.status == 'declined' ) {
                    baseClass += 'bg-gray-700 text-white border-gray-700';
                }

                if ( this.model.status == 'legacy-archived' ) {
                    baseClass += 'bg-gray-200 text-gray-700 border-gray-200';
                }

                return baseClass;
            }
        },
        methods: {
            changeStatusBubleColor(status)
            {
                if ( status == 'pending' ) {
                    return 'primary';
                }

                if ( status == 'in-progress' ) {
                    return 'orange-500';
                }

                if ( status == 'quote-sent' ) {
                    return 'green-400';
                }

                if ( status == 'won' ) {
                    return 'green-600';
                }

                if ( status == 'lost' ) {
                    return 'red-600';
                }

                if ( status == 'declined' ) {
                    return 'gray-700';
                }

                if ( status == 'legacy-archived' ) {
                    return 'gray-200';
                }
            },
            changeStatusTextColor(status)
            {
                if ( status !== 'legacy-archived' ) {
                    return this.changeStatusBubleColor(status);
                }

                return 'gray-700';
            },
            
            updateStatus(status)
            {
                this.$refs.actionsSubmenu.hide();

                if ( status !== this.model.status ) {
                    this.$emit('update', status);
                }
            }
        }
    }
</script>