import TrustUpRedirector from '~base/components/TrustUpRedirector';

import ProfessionalsIndex from '~base/components/Professionals/Index';
import ProfessionalsBase from '~base/components/Professionals/Base';

import InboxBase from '~base/components/Professionals/Inbox/Base';
import VisibilityBase from '~base/components/Professionals/Visibility/Base';
import VoipBase from '~base/components/Professionals/Voip/Base';

import {routes as HomepageRoutes} from '~base/routes/homepage';
import {routes as InformationRoutes} from '~base/routes/informations';
import {routes as FacebookPageRoutes} from '~base/routes/facebook-pages';
import {routes as ProjectRoutes} from '~base/routes/projects';
import {routes as RatingRoutes} from '~base/routes/ratings';
import {routes as StatisticRoutes} from '~base/routes/statistics';
import {routes as RequestRoutes} from '~base/routes/inbox/requests';
import {routes as SettingsRoutes} from '~base/routes/inbox/settings';
import voipRoutes from '~base/routes/voip';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

const routes = [

    generator.create({
        path: '/',
        name: 'homepage',
        redirect: {
            name: 'professionals.index'
        }
    }).public().get(),

    generator.create({
        path: '/redirection',
        name: 'redirection',
        component: TrustUpRedirector
    }).protected().get(),

    generator.create({
        path: '/professionals',
        component: ProfessionalsIndex,
        name: 'professionals.index',
        meta: {
            title: 'Professionals',
        },
    }).protected().get(),

    // Routes needing a selected professional
    generator.create({
        path: '/professionals/:professional',
        component: ProfessionalsBase,
        meta: {
            title: '/',
        },
        children: [
            generator.create({
                path: 'visibility',
                component: VisibilityBase,
                name: 'professional.visibility.base',
                redirect: to => {
                    const { hash, params, query } = to;
                    return {
                        name: 'professional.visibility.projects.index',
                        params: params
                    }
                },
                children: [...HomepageRoutes, ...InformationRoutes, ...FacebookPageRoutes, ...ProjectRoutes, ...RatingRoutes, ...StatisticRoutes]
            }).protected().get(),
            
            generator.create({
                path: 'inbox',
                component: InboxBase,
                name: 'professional.inbox.base',
                redirect: to => {
                    const { hash, params, query } = to;
                    return {
                        name: 'professional.inbox.requests.index',
                        params: params
                    }
                },
                children: [...RequestRoutes, ...SettingsRoutes]
            }).protected().get(),

            generator.create({
                path: 'voip',
                component: VoipBase,
                name: 'professional.voip.base',
                redirect: to => {
                    const { params } = to;
                    return {
                        name: 'professional.inbox.voip.calls.index',
                        params
                    }
                },
                children: [...voipRoutes.calls]
            }).protected().get()     
        ]
    }).protected().get()
];

export default routes;