export default {
    apiHeaders: state => {
        if ( ! state.token ) {
            return {};
        }

        return {
            'Authorization': state.token.token_type + ' ' + state.token.access_token
        };
    },
    professionalCanAccessVoipCalls: state => {
        if (state.professional && state.professional.permissions) {
            const { voip: { calls = false } } = state.professional.permissions;
            return calls;
        }
        return false;
    }
}