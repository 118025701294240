<template>
    <requests-list
        :title="$t('inbox.requests.index.title')"
        :empty-message="$t('inbox.requests.index.empty')"
        :empty-filters-message="$t('inbox.requests.index.empty_filters')"
        action="getRequests"
    ></requests-list>
</template>

<script>

    import RequestsListVue from './_Partials/List.vue';

    export default {
        components: {
            'requests-list': RequestsListVue
        },
        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'inbox-requests');
        }
    }
</script>