<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('inbox.settings.title') }}</template>

        <template v-slot:tabs>
            <v-spa-layout-page-tab :tab="{name: 'professional.inbox.settings.quick-answers.index', label: $t('inbox.settings.links.quick-answers')}"></v-spa-layout-page-tab>
            <v-spa-layout-page-tab :tab="{name: 'professional.inbox.settings.automatic-answers.index', label: $t('inbox.settings.links.automatic-answers')}"></v-spa-layout-page-tab>
        </template>

        <template v-slot:content>
            <slot name="settings-content"></slot>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';

    export default {

        components: {
            'tmp-spa-page': Page,
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'inbox-settings');
        }
    }
</script>
