import axios from 'axios';

export default {

    getProfessionals(context)
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals`,
            key: 'professionals'
        });
    },

    getProfessional(context, id)
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${id}`,
            key: 'professional'
        });
    },

    getProfessionalWorkfieldsIds(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/workfields/ids`,
            key: 'ids'
        });
    },

    getNotificationPreferences(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/notification-preferences`,
            key: 'notifications'
        });
    },
    
    setProfessional(context, professional)
    {
        context.commit('setProfessional', professional);
        context.dispatch('setVariableInTitle', {variable: 'professionalname', value: professional.company});
    },


}