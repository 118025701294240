<template>
    <answer-form ref="form" @ready="$refs.form.loading = false" @submitted="store" :button="$t('form.buttons.create')"></answer-form>
</template>

<script>

    import {mapActions} from 'vuex';
    import Base from './../Base';
    import Form from './Form';

    export default {
        components: {
            'settings-base': Base,
            'answer-form': Form
        },

        data() {
            return {
                answer: null
            }
        },

        methods: {

            store()
            {
                this.$refs.form.form.post( `${window.api_url}/me/professionals/${this.$store.state.professional.id}/automatic-answers` )
                    .then((response) => {
                        window.Toasteo.success(this.$t('inbox.settings.automatic_answers.index.modals.edit.created'));
                        this.$modal.hide('automatic-answers-create')
                        this.$bus.$emit('automatic-answers:created')
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$refs.form.form.stop();
                    })
            }
        },
        created() {
            this.$bus.$on('ctrl-s', (resource) => {
                this.store();
            });
        },
        beforeDestroy() {
            this.$bus.$off('ctrl-s');
        }
    }
</script>
