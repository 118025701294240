<template>
    <div class="voip-base h-full">
        <router-view :key="'professional-' + $store.state.professional.id + '-' + $store.state.professional.updated_at"></router-view>
    </div>
</template>

<script>
    export default {
        //
    }
</script>
