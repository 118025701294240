<template>
    <div>
        <div class="p-4 border-b border-gray-100" v-if="model.images && model.images.length > 0">
            <p class="text-lg mb-2">Images partagées</p>
            <div class="flex flex-wrap -mx-2">
                <div class="w-1/3 px-2" v-for="(image, index) in model.images" :key="`image-${index}`">
                    <img class="w-full"  :src="image.url.small">
                </div>
            </div>
        </div>
        <div class="p-4 border-b border-gray-100" v-if="model.files && model.files.length > 0">
            <p class="text-lg mb-2">Fichiers partagées</p>
            <div v-for="(file, index) in model.files" class="block w-full" :key="`file-${index}`">
                <a :href="file.url" target="_blank" class="text-blue-400">
                    <img src="/images/icons/small/files/pdf.svg">
                    <p v-text="file.name"></p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['model']
    }
</script>
