export default {

    getRatings(context, {professionalId, page = 1})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/ratings?page=${page}`
        });
    },

    getRating(context, {professionalId, ratingId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/ratings/${ratingId}`,
            key: 'rating'
        });
    }

}