<template>
    <settings-base>
        <template slot="settings-content">
            <v-spa-panel :title="$t('inbox.settings.edit.title')" :loading="false">
                azer
            </v-spa-panel>
        </template>
    </settings-base>
</template>

<script>
    import Base from './Base';

    export default {
        components: {
            'settings-base': Base,
        },

        created() {
            console.log('coucou');
        }
    }
</script>