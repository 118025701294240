<template>
    <div>
        <div class="mb-3 text-center">
            <p class="text-sm font-thin text-gray-600">{{ model.received_at | moment('YYYY-MM-DD HH:mm:ss').format('dddd D MMMM YYYY') }}</p>
        </div>
        <div class="p-4 mx-auto mb-8 bg-white border-t-4 rounded-lg shadow max-w-500 border-trustup">
            <template v-if="model.model.workfield">
                <p class="text-xl" v-text="model.model.workfield.title"></p>
            </template>
            <template v-if="model.model.start_time">
                <p class="text-sm text-gray-8000">{{ $t('inbox.requests.show.panels.quote_informations.start_time') }} : <span v-text="model.model.start_time.title"></span></p>
            </template>
            <div class="mt-2 text-sm font-thin text-gray-500">
                <p v-html="model.model.text"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['model']
    }
</script>

