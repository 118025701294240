import axios from 'axios';
import loadGoogleMapsApi from 'load-google-maps-api';

export default {

    getWorkfields(context)
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/workfields`,
            key: 'workfields'
        });
    },

    getWorkfieldsAsTree(context)
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/workfields/tree`,
            key: 'workfields'
        });
    },

    getLocales(context)
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/locales`,
            key: 'locales'
        });
    },

    getData(context, {url, urlParams = null, key = null, data = {}, method = 'GET', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                if ( ! key ) {
                    return resolve(response.data);
                }
                resolve(response.data[key]);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    postData(context, {url, urlParams = null, key, data = {}, method = 'POST', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    updateData(context, {url, urlParams = null, key, data = {}, method = 'PUT', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    ping(context, {url, method = 'GET'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: method,
                url: url
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to ping the url.', {error: error, url: url, method: method});
                reject(error);
            });
        });
    },

    deleteData(context, {url, itemToDelete = null, array = null, key = 'id'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: url
            })
            .then((response) => {
                if ( array && key && itemToDelete ) {
                    context.dispatch('deleteItemInArray', {array: array, key: key, itemToDelete: itemToDelete});
                }
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to delete data.', {error: error, url: url, array: array, key: key});
                reject(error);
            });
        });
    },

    deleteItemInArray(context, {array, key, itemToDelete})
    {
        for ( let i = 0; i < array.length; i++ ) {
            if ( array[i][key] == itemToDelete ) {
                array.splice(i, 1);
            }
        }
    },

    setVariableInTitle(context, {variable, value})
    {
        if ( document.title.includes('{'+variable+'}') ) {
            document.title = document.title.replace('{'+variable+'}', value);
        };
    },

    loadGoogleMaps(context)
    {
        return new Promise((resolve, reject) => {
            if ( context.state.google ) {
                resolve();
            }

            loadGoogleMapsApi({
                key: window.google_maps_key,
                libraries: ['places']
            }).then((google) => {
                context.commit('setGoogle', google);
                resolve();
            });
        });
    },

    formatLocation(context, { position, address }) {
        return new Promise(function(resolve, reject) {
            let location = {
                display: '',
                country: '',
                province: '',
                city: '',
                postal_code: '',
                street_name: '',
                street_number: '',
                latitude: '',
                longitude: ''
            };

            if (address.administrative_area_level_1) {
                location.province = address.administrative_area_level_1;
            }
            if (address.country) {
                location.country = address.country;
            }
            if (address.locality) {
                location.city = address.locality;
            }
            if (address.postal_code) {
                location.postal_code = address.postal_code;
            }
            if (address.route) {
                location.street_name = address.route;
            }
            if (address.street_number) {
                location.street_number = address.street_number;
            }
            if (address.latitude) {
                location.latitude = address.latitude;
            }
            if (address.longitude) {
                location.longitude = address.longitude;
            }

            if (position) {
                location.latitude = position.coords.latitude;
                location.longitude = position.coords.longitude;
            }
            resolve(location);
        });
    },

}