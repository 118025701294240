<template>
    <div class="flex flex-col h-full -mt-6">
        <div class="flex-auto">
            <template v-if="$store.state.professional">
                <router-link class="flex items-center w-full px-2 py-4 mb-2 text-left bg-gray-100 rounded" :to="{name: 'professionals.index'}">
                    <div class="w-10 h-10 mr-4 bg-gray-400 bg-center bg-no-repeat bg-contain rounded" :style="{'background-image': `url(${$store.state.professional.logo})`}"></div>
                    <span class="text-sm text-black">
                        {{ $store.state.professional.company }}
                    </span>
                </router-link>
                <v-spa-layout-sidebar-link section="visibility-projects" :to="{name: 'professional.visibility.projects.index', params: {professional: $store.state.professional.id}}" icon="/icons/small/image.svg">
                    {{ $t('layout.sidebar.visibility.projects') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-ratings" :to="{name: 'professional.visibility.ratings.index', params: {professional: $store.state.professional.id}}" icon="/icons/small/star.svg">
                    {{ $t('layout.sidebar.visibility.ratings') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-informations" :to="{name: 'professional.visibility.informations.edit', params: {professional: $store.state.professional.id}}" icon="/icons/small/trustup.svg">
                    {{ $t('layout.sidebar.visibility.informations') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-facebook-pages" :to="{name: 'professional.visibility.facebook-pages.edit', params: {professional: $store.state.professional.id}}" icon="/icons/small/facebook.svg">
                    {{ $t('layout.sidebar.visibility.facebook_pages') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="visibility-statistics" :to="{name: 'professional.visibility.statistics.show', params: {professional: $store.state.professional.id}}" icon="/icons/small/chart-line.svg">
                    {{ $t('layout.sidebar.visibility.statistics') }}
                </v-spa-layout-sidebar-link>
                <div class="w-full mt-2 mb-2 border-t border-gray-200"></div>
                <v-spa-layout-sidebar-link section="inbox-requests" :to="{name: 'professional.inbox.requests.index', params: {professional: $store.state.professional.id}}" icon="/icons/small/inbox.svg">
                    {{ $t('layout.sidebar.inbox.requests') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link section="inbox-archives" :to="{name: 'professional.inbox.requests.archives', params: {professional: $store.state.professional.id}}" icon="/icons/small/archive.svg">
                    {{ $t('layout.sidebar.inbox.archives') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link v-if="professionalCanAccessVoipCalls" section="voip-calls" :to="{name: 'professional.inbox.voip.calls.index', params: {professional: $store.state.professional.id}}" padding="py-2 lg:py-3 lg:pl-6 lg:pr-2" :opacities="{base: '50', hover: '100'}" icon-size="h-5 w-5 -mt-0.5" icon="/icons/small/phone-call.svg">
                    {{ $t('layout.sidebar.voip.calls') }}
                </v-spa-layout-sidebar-link>
                <!-- <v-spa-layout-sidebar-link section="inbox-trash" :to="{name: 'professional.inbox.requests.trash', params: {professional: $store.state.professional.id}}" icon="/icons/small/delete-bin.svg">
                    {{ $t('layout.sidebar.inbox.trash') }}
                </v-spa-layout-sidebar-link> -->
                <v-spa-layout-sidebar-link section="inbox-settings" :to="{name: 'professional.inbox.settings.quick-answers.index', params: {professional: $store.state.professional.id}}" icon="/icons/small/settings.svg">
                    {{ $t('layout.sidebar.inbox.settings') }}
                </v-spa-layout-sidebar-link>
            </template>
        </div>
    </div>
</template>



<script>

    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['professionalCanAccessVoipCalls'])
        }
    }
</script>
