<template>
    <div>
        <div class="flex p-4 overflow-x-auto bg-white border-t border-gray-200" v-if="quickAnswers !== null">
            <template v-if="quickAnswers && quickAnswers.length > 0">
                <a href="#" class="flex-shrink-0 inline-block px-4 py-2 mr-2 text-sm text-black bg-gray-200 rounded-full" v-for="quickAnswer in quickAnswers" :key="quickAnswer.id" @click.prevent="setQuickAnswer(quickAnswer)">
                    {{ quickAnswer.title }}
                </a>
                <a href="#" class="flex-shrink-0 inline-block px-4 py-2 mr-2 text-sm text-black bg-gray-200 rounded-full" @click.prevent="$router.replace({name: 'professional.inbox.settings.quick-answers.index', params: {professional: $store.state.professional.id}})">
                    {{ $t('inbox.requests.show.chat.edit_quick_answers') }}
                </a>
            </template>
            <template v-if="quickAnswers && quickAnswers.length == 0">
                <a href="#" class="text-sm text-gray-600" @click.prevent="makeDefaultQuickAnswers">{{ $t('inbox.requests.show.chat.no_quick_answers') }}</a>
            </template>
        </div>
        <div class="p-4 bg-white border-t border-gray-200">
            <form @submit.prevent="addMessage">
                <div class="flex mb-2 space-x-2" v-if="images.length > 0">
                    <div class="w-16 h-16 bg-center bg-cover border rounded border-grey-200" :style="{'background-image': 'url('+image.source+')'}" v-for="(image, index) in images" :key="`uploaded-file-${index}`"></div>
                </div>
                <div class="mb-2" v-if="files.length > 0">
                    <div class="flex items-center space-x-2 text-blue-400" v-for="(file, index) in files" :key="`uploaded-file-${index}`">
                        <i class="far fa-file-alt"></i>
                        <span v-text="file.name"></span>
                    </div>
                </div>
                <div class="flex items-center p-1 bg-gray-100 border border-gray-400 border-solid rounded-chat-input">
                    <div>
                        <label class="block p-2 text-gray-600 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-image"><circle cx="8" cy="9" r="1"></circle><rect x="3" y="4" width="18" height="16"></rect><path d="M4.5 20l9.5-9.5 7 7"></path></svg>
                            <input type="file" ref="imageUploader" class="hidden" id="chat-new-message-images-input" @change.prevent="handleImageUpload" multiple/>
                        </label>
                    </div>
                    <div>
                        <label class="block p-2 text-gray-600 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-link"><path d="M9 15l6-6"></path><path d="M13.472 19A6 6 0 1 1 5 10.528"></path><path d="M10.528 5A6 6 0 1 1 19 13.472"></path></svg>
                            <input type="file" ref="fileUploader" class="hidden" id="chat-new-message-files-input" @change.prevent="handleFileUpload" multiple/>
                        </label>
                    </div>
                    <!-- <div class="mr-2">
                        <v-spa-emoji-picker @emoji-added="addEmoji"></v-spa-emoji-picker>
                    </div> -->
                    <textarea id="conversation-new-message-input" rows="1"  class="flex-1 px-4 py-1 text-sm bg-gray-100 outline-none" v-model="form.message" :placeholder="$t('inbox.requests.show.chat.message.placeholder')" resizable="false"></textarea>
                    <v-spa-loading-button extra-class="overflow-hidden rounded-chat-input" :loading="form.is_loading">{{ $t('inbox.requests.show.chat.message.button') }}</v-spa-loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

    import ResizableTextarea from '~base/classes/ResizableTextarea';
    import UploadedFile from '~base/classes/UploadedFile';
    import UploadedImage from '~base/classes/UploadedImage';
    import Form from '~spa/classes/Form';
    import ChatVue from '../_Partials/Chat.vue';
    import imageUploadMixin from '~spa/components/_Form/image-upload-mixin';

    export default {
        components: {
            'chat': ChatVue,
        },

        mixins: [imageUploadMixin],

        props: ['model'],

        data() {
            return {
                textarea: null,
                quickAnswers: null,
                images: [],
                files: [],
                form: new Form({
                    message: ''
                }, {reset: true}),
            }
        },

        methods: {
            handleImageUpload()
            {
                if ( this.loading ) {
                    return;
                }

                let images = this.$refs.imageUploader.files;
                for ( let i = 0; i < images.length; i++ ) {
                    this.processFile(new UploadedImage, images[i])
                        .then(data => {
                            console.log('New image:', data);
                            this.images.push(data);
                            this.resetInput('chat-new-message-images-input');
                        });
                } 
            },
            handleFileUpload()
            {
                if ( this.loading ) {
                    return;
                }

                let files = this.$refs.fileUploader.files;
                for ( let i = 0; i < files.length; i++ ) {
                    this.processFile(new UploadedFile, files[i])
                        .then(data => {
                            console.log('New file:', data);
                            this.files.push(data);
                            this.resetInput('chat-new-message-images-input');
                        });
                } 
            },

            processFile(processor, file)
            {
                return new Promise((resolve) => {
                    processor.check(file)
                        .then(() => processor.get(file))
                        .then(data => resolve(data));
                })
            },

            resetInput(id)
            {
                document.getElementById(id).value = null;
            },

            addEmoji(emoji) {
                let value = this.insertAtCaret('chat-new-message-textarea', emoji);
                if ( value ) {
                    this.form.message = value;
                } else {
                    this.form.message += emoji;
                }
            },

            addMessage()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                const data = new FormData();
                data.append('message', this.form.message);

                for ( let i = 0; i < this.images.length; i++ ) {
                    data.append('images[]', this.images[i].source);
                }
                for ( let i = 0; i < this.files.length; i++ ) {
                    data.append('files[]', this.files[i].file);
                }

                this.form.load();
                axios.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/requests/${this.model.id}/messages`, data)
                    .then((response) => {
                        this.$emit('new-message', response.data);
                        this.resetFormData();
                        this.form.stop();
                        window.Toasteo.success( this.$t('inbox.requests.show.chat.message.sent') );
                    })
                    .catch((error) => {
                        console.error(error);
                        this.form.stop();
                        window.Toasteo.error( this.$t('inbox.requests.show.chat.message.error') );
                    });
            },

            resetFormData()
            {
                this.form.message = '';
                this.images = this.videos = this.files = [];
                this.resizeTextarea();
            },

            setQuickAnswer(quickAnswer)
            {
                if ( this.form.message == '' || this.form.message == quickAnswer.answer ) {
                    this.form.message = quickAnswer.answer;
                    this.resizeTextarea();
                    return;
                }

                if ( window.confirm("This will replace your current message, are you sure you wish to continue?") ) { 
                    this.form.message = quickAnswer.answer;
                    this.resizeTextarea();
                }
            },

            makeDefaultQuickAnswers()
            {
                this.$store.dispatch('makeDefaultQuickAnswers', {professionalId: this.$store.state.professional.id, force: true})
                    .then((response) => {
                        this.quickAnswers = response.data.quick_answers;
                        this.$emit('require-scroll');
                    })
            },

            resizeTextarea()
            {
                this.$nextTick(() => this.textarea.resize());
            }
        },

        created() {
            this.$store.dispatch('getQuickAnswers', {professionalId: this.$store.state.professional.id})
                .then(answers => {
                    this.quickAnswers = answers;
                    this.$emit('require-scroll');
                });
        },

        mounted() {
            this.textarea = new ResizableTextarea({id: 'conversation-new-message-input'});
            this.textarea.init();
        }
    }
</script>
