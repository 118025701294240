<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('visibility.projects.index.title') }}</template>

        <template v-slot:title-button>
            <v-spa-link-button :to="{name: 'professional.visibility.projects.create', params: {professional: $store.state.professional.id}}">
                <span class="inline-block md:hidden">{{ $t('visibility.projects.index.button_small') }}</span>
                <span class="hidden md:inline-block">{{ $t('visibility.projects.index.button') }}</span>
            </v-spa-link-button>
        </template>

        <template v-slot:content>
            <div class="relative p-12" v-if="loading">
                <v-spa-loader></v-spa-loader>
            </div>
            <div v-else>
                <div v-if="!rows.length">
                    <div class="mx-auto py-12">
                        <div class="w-[400px] mx-auto">
                            <v-spa-lottie-empty-2></v-spa-lottie-empty-2>
                        </div>
                        <p class="my-12 text-lg font-thin text-center text-gray-700">{{ $t('visibility.projects.index.empty') }}</p>
                    </div>
                </div>

                <div class="grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 md:gap-4 -mx-2">
                    <div class="w-full aspect-video" v-for="(project, index) in rows" :key="project.id">
                        <router-link :to="{name: 'professional.visibility.projects.edit', params: {professional: $store.state.professional.id, project: project.id}}" class="relative block bg-white h-fit border border-gray-200 rounded cursor-pointer group">
                            <v-spa-loader v-if="deleting.includes(project.id)"></v-spa-loader>
                            <div class="absolute top-0 right-0 hidden mt-2 mr-2 group-hover:block">
                                <a class="inline-block p-1 bg-white rounded" href="#" @click.prevent="deleteProject(project, index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 devfont devfont-delete-bin"><path d="M3 7h18"></path><path d="M8 7V3h8v4"></path><path d="M5 7v14h14V7.5"></path><path d="M10 12v4"></path><path d="M14 12v4"></path></svg>
                                </a>
                            </div>
                            <div class="block w-full aspect-video mx-auto bg-center bg-cover" :style="{'background-image': 'url('+project.thumbnail+')'}"></div>
                            <div class="p-2">
                                <span class="font-thin text-gray-800 group-hover:text-gray-900">{{ project.title }}</span>
                                <div>
                                    <span class="inline-block mr-2 text-xs font-thin text-gray-600 group-hover:text-gray-800">{{ project.created_at | moment('YYYY-MM-DD HH:mm:ss').fromNow() }}</span>
                                    <span class="inline-block text-xs font-thin text-gray-600 group-hover:text-gray-800">{{ $t('visibility.projects.index.photos_count', {count: project.images_count}) }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="flex justify-center py-4" v-if="pagination && pagination.last_page !== 1">
                    <paginate
                        v-model="pagination.current_page"
                        :no-li-surround="true"
                        :page-count="pagination.last_page"
                        :click-handler="getProjects"
                        :prev-text="$t('pagination.previous')"
                        :prev-link-class="'prev'"
                        :next-text="$t('pagination.next')"
                        :next-link-class="'next'"
                        :container-class="'pagination'"
                        :active-class="'active'"
                        ref="paginate"
                    ></paginate>
                </div>
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import Layout from '~base/components/Layout';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';
    import mixin from '~spa/components/_Table/index-mixin.js';
    import Paginate from 'vuejs-paginate';

    export default {
        components: {
            Paginate,
            'layout': Layout,
            'tmp-spa-page': Page
        },

        mixins: [mixin],

        data() {
            return {
                pagination: null,
                deleting: []
            }
        },

        methods: {
            deleteProject(project, index)
            {
                if ( ! window.confirm("Êtes-vous sur de vouloir supprimer ce projet ?") ) {
                    return;
                }

                this.deleting.push(project.id);

                this.$store.dispatch('deleteProject', {professionalId: this.$store.state.professional.id, projectId: project.id, array: this.rows})
                    .then(() => {
                        this.deleting = this.deleting.filter(id => id !== project.id);
                        window.Toasteo.success(this.$t('visibility.projects.deleted'));
                    })
            },

            getProjects(page = 1)
            {
                this.loading = true;

                this.$store.dispatch('getProjects', {professionalId: this.$store.state.professional.id, page: page})
                    .then(data => {
                        this.rows = data.projects;
                        this.pagination = data.pagination;
                        this.loading = false;

                        if ( this.$route.name == 'professional.visibility.projects.index' ) {
                            this.$router.replace({name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}, query: {page: this.pagination.current_page}}).catch(()=>{});
                        }
                    });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-projects');

            this.getProjects(this.$route.query.page);
        }
    }
</script>
