import RatingsIndex from '~base/components/Professionals/Visibility/Ratings/Index';
import RatingsCollect from '~base/components/Professionals/Visibility/Ratings/Collect';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'ratings',
        component: RatingsIndex,
        'name': 'professional.visibility.ratings.index',
        meta: {
            title: '{professionalname} - Ratings'
        }
    }).protected().get(),

    generator.create({
        path: 'ratings/collect',
        component: RatingsCollect,
        'name': 'professional.visibility.ratings.collect',
        meta: {
            title: '{professionalname} - Rating'
        }
    }).protected().get()
]