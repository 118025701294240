import i18n from "~base/_i18n";

export default {
    
    'TOKEN/SET'(state, token) {
        state.token = token;
        
        if ( token ) {
            window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
        } else {
            window.axios.defaults.headers.common['Authorization'] = null;
        }
    },

    /**
     * Remove the token from the localstorage.
     *
     * @param {Object} state Vuex's state
     */
    removeToken(state)
    {
        state.token = null;
        window.axios.defaults.headers.common['Authorization'] = null;
    },

    /**
     * Set the professional.
     * 
     * @param {Object} state Vuex's state
     * @param {Object} professional
     */
    setProfessional(state, professional) {
        state.professional = professional;
        
        if ( professional ) {
            if ( typeof window.intercomSettings === 'object' ) {
                window.intercomSettings.professional = professional.company;
                window.intercomSettings.professional_id = professional.id;
                window.intercomSettings.professional_created_at = professional.created_at;
            }

            if ( typeof window.Intercom === 'function' ) {
                window.Intercom('update', window.intercomSettings); 
            }
        }
    },

    /**
     * Set the google maps instance.
     * 
     * @param {Object} state Vuex's state
     * @param {Object} google
     */
    setGoogle(state, google) {
        state.google = google;
    },

    /**
     * Set the current section of the app.
     * 
     * @param {Object} state Vuex's state
     * @param {String} section
     */
    setSection(state, section) {
        state.section = section;
    },

    /**
     * Set the sidebar section of the app.
     * 
     * @param {Object} state Vuex's state
     * @param {String} section
     */
    setSidebarSection(state, section) {
        state.sidebarSection = section;
    },

    /**
     * Set the status of the application loading.
     * 
     * @param {Object}  state Vuex's state
     * @param {Boolean} status
     */
    setApplicationLoadingStatus(state, status) {
        state.loadingApplication = status;
    },

    setLocale(state, locale) {
        if ( ! locale || ! i18n.availableLocales.includes(locale) ) {
            return;
        }
        
        state.locale = locale;
        i18n.locale = locale;
        window.moment.locale(locale);

        if ( locale !== 'fr' && typeof window.Intercom === 'function' ) {
            window.Intercom('update', {
                "hide_default_launcher": true
            });
        }
    },

    setGoogleTranslateStatus(state, status) {
        state.googleTranslateIsActive = status;
    }
};