<template>
    <div class="p-4 border-b border-gray-100" v-if="model.model.address">
        <div id="request-map" class="h-48"></div>
        <span class="block text-gray-700">
            {{ model.model.address.display }}
            <a class="block text-sm text-blue-600 underline" :href="'https://www.google.com/maps/dir/' + this.$store.state.professional.address.latitude + ',' + this.$store.state.professional.address.longitude + '/' + model.model.address.latitude + ',' + model.model.address.longitude" target="_blank">
                {{ $t('inbox.requests.show.panels.quote_address.itinerary') }}
            </a>
        </span>
    </div>
</template>

<script>

    import Map from '~base/classes/Map';

    export default {
        props: ['model'],

        data() {
            return {
                map: new Map(6)
            }
        },

        methods: {
            loadMap()
            {
                if ( ! this.model.model ) {
                    return;
                }

                this.$store.dispatch('loadGoogleMaps')
                    .then(() => {
                        this.map.center = {lat: this.model.model.address.latitude, lng: this.model.model.address.longitude};
                        this.map.createMap('request-map');

                        console.log([
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.model.model.address.latitude, lng: this.model.model.address.longitude}
                            },
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.$store.state.professional.address.latitude, lng: this.$store.state.professional.address.longitude}
                            }
                        ]);

                        this.map.createMarkers([
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.model.model.address.latitude, lng: this.model.model.address.longitude}
                            },
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.$store.state.professional.address.latitude, lng: this.$store.state.professional.address.longitude}
                            }
                        ]);
                    });
            },
        },

        created() {
            this.loadMap();
        }
    }
</script>
