export default {

    getRequests(context, {professionalId, filters})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/`,
            urlParams: {...{archived: '0'}, ...filters}
        });
    },

    getRequest(context, {professionalId, requestId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/${requestId}`,
            key: 'request'
        });
    },

    getStatusGroups(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/count-by-status-group`
        });
    },

    getFormSubmission(context, {professionalId, submissionId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/form-submissions/${submissionId}`,
            key: 'form_submission'
        });
    },

    markRequestAsRead(context, {professionalId, requestId})
    {
        return context.dispatch('ping', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/${requestId}/read`,
            method: 'PUT'
        });
    },

    updateRequestStatus(context, {professionalId, requestId, status})
    {
        return context.dispatch('ping', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/${requestId}/status/${status}`,
            method: 'PUT'
        });
    },

    toggleRequestArchive(context, {professionalId, requestId})
    {
        return context.dispatch('ping', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/${requestId}/archive`,
            method: 'PUT'
        });
    },

    toggleRequestFavorite(context, {professionalId, requestId})
    {
        return context.dispatch('ping', {
            url: `${window.api_url}/me/professionals/${professionalId}/requests/${requestId}/favorite`,
            method: 'PUT'
        });
    },

}