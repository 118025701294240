export default {

    getFacebookPages(context, {professionalId})
    {
        return context.dispatch('getData', {
            url: `${window.api_url}/me/professionals/${professionalId}/facebook-pages`,
            key: 'facebook_pages'
        });
    },

    storeFacebookPage(context, {professionalId, page})
    {
        return context.dispatch('postData', {
            url: `${window.api_url}/me/professionals/${professionalId}/facebook-pages`,
            data: {page: page}
        });
    },

    deleteFacebookPage(context, {professionalId, pageId})
    {
        return context.dispatch('deleteData', {
            url: `${window.api_url}/me/professionals/${professionalId}/facebook-pages/${pageId}`
        });
    }

}