<template>
    <v-spa-panel>
        <p class="font-thin text-gray-700">
            {{ $t('inbox.requests.show.panels.guest_quote.text') }}
        </p>
    </v-spa-panel>
</template>

<script>
    export default {
        props: ['model']
    }
</script>

