<template>
    <a href="#" @click.prevent="toggle" class="w-full flex items-center text-sm text-gray-800 py-2 group">
        <span class="flex flex-no-shrink justify-center items-center h-4 w-4 rounded-full border-2 group-hover:border-primary mr-2 border-gray-200" :class="{ 'border-primary': isSelected, 'border-gray-200': ! isSelected }">
            <template v-if="isSelected">
                <span class="h-2 w-2 rounded-full bg-primary"></span>
            </template>
        </span>
        <span class="flex-1">{{ album.title }}</span>
    </a>
</template>

<script>
    export default {
        props: ['project', 'album', 'form', 'last'],

        methods: {
            toggle()
            { 
                for ( let i = 0; i < this.form.website_albums.length; i++ ){
                    if ( this.form.website_albums[i] == this.album.id ) {
                        this.form.website_albums.splice(i, 1);
                        return;
                    }
                }

                this.form.website_albums.push(this.album.id);
            }
        },

        computed: {
            isSelected()
            {
                for ( let i = 0; i < this.form.website_albums.length; i++ ){
                    if ( this.form.website_albums[i] == this.album.id ) {
                        return true;
                    }
                }

                return false;
            },
        }
    }
</script>
