<template>
    <informations-base>
        <template slot="informations-content">
            <v-spa-panel :title="$t('visibility.informations.intervention_zone.title')" :loading="false">
                <form @submit.prevent="update">
                    <div class="p-6 border-b border-grey-lighter">
                        <v-spa-input id="form_intervention_zone" :form="form" input-name="intervention_zone"></v-spa-input>
                    </div>
                    <div class="flex flex-row-reverse p-6">
                        <v-spa-loading-button :loading="form.is_loading">{{ $t('form.buttons.update') }}</v-spa-loading-button>
                    </div>
                </form>
            </v-spa-panel>
        </template>
    </informations-base>
</template>

<script>
    import Form from '~spa/classes/Form';
    import Base from './Base';

    export default {
        components: {
            'informations-base': Base
        },

        data() {
            return {
                locales: [],
                loading: true,
                form: new Form({
                    intervention_zone: 20
                })
            }
        },

        methods: {
            update() {
                this.form.put(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/intervention-zone`)   
                    .then((response) => {
                        window.Toasteo.success( this.$t('visibility.informations.intervention_zone.success') );
                    });
            }
        },

        created() {
            this.form.fill(this.$store.state.professional);
        }
    }
</script>