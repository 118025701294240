<template>
    <div>
        <v-spa-table-loader v-show="loading"></v-spa-table-loader>
        <answer-form v-show="!loading" ref="form" @ready="getAnswer" @submitted="update" :answer="answer" :button="$t('form.buttons.update')"></answer-form>
    </div>
</template>

<script>

    import {mapActions} from 'vuex';
    import Base from './../Base';
    import Form from './Form';

    export default {
        components: {
            'settings-base': Base,
            'answer-form': Form
        },

        data() {
            return {
                answer: null,
                loading: false
            }
        },

        props: ['answerId'],

        methods: {
            getAnswer()
            {
                this.loading = true
                this.$store.dispatch('getQuickAnswer', {professionalId: this.$store.state.professional.id, answerId: this.answerId})
                    .then(answer => {
                        this.answer = answer;
                        this.$refs.form.form.fill({
                            answer: answer.answer,
                            title: answer.title,
                            order: answer.order,
                        });
                        this.loading = false
                        this.$refs.form.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                        window.Toasteo.error('Answer #' + this.$route.params.answer + ' not found.');
                        this.$router.push({name: 'professional.inbox.settings.quick-answers.index', params: {professional: this.$store.state.professional.id}});
                    });
            },

            update()
            {
                this.$refs.form.form.put( `${window.api_url}/me/professionals/${this.$store.state.professional.id}/quick-answers/${this.answer.id}` )
                    .then((response) => {
                        window.Toasteo.success(this.$t('inbox.settings.quick_answers.index.modals.edit.updated'));
                        this.$modal.hide('quick-answers-edit')
                        this.$bus.$emit('quick-answers:updated')
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$refs.form.form.stop();
                    })
            }
        },
        created() {
            this.$bus.$on('ctrl-s', (resource) => {
                this.store();
            });
        },
        beforeDestroy() {
            this.$bus.$off('ctrl-s');
        }
    }
</script>
