<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('voip.calls.title') }}</template>
        <template v-slot:content>
            <div class="relative py-24" v-if="calls.loading"><v-spa-loader></v-spa-loader></div>
            <template v-else>
                <ul>
                    <voip-call v-for="call in calls.data" :key="call.id" :call="call" />
                </ul>
                <div class="flex justify-center py-4" v-if="calls.pagination.last_page > 1">
                    <paginate
                        v-model="calls.pagination.current_page"
                        :no-li-surround="true"
                        :page-count="calls.pagination.last_page"
                        :click-handler="setPage"
                        :prev-text="$t('pagination.previous')"
                        :prev-link-class="'prev'"
                        :next-text="$t('pagination.next')"
                        :next-link-class="'next'"
                        :container-class="'pagination'"
                        :active-class="'active'"
                    ></paginate>
                </div>
            </template>
        </template>
    </v-spa-layout-page>
</template>

<script>;
    import VoipCall from '~base/components/Professionals/Voip/Calls/Call';
    import Paginate from 'vuejs-paginate';
    import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        VoipCall,
        Paginate
    },
    data() {
        return {
            calls: {
                loading: true,
                data: [],
                pagination: {
                    current_page: 1,
                    last_page: 1,
                    per_page: 25
                }
            }
        }
    },
    methods: {
        ...mapActions(['getVoipCalls']),
        async setCalls(page)
        {
            this.calls.loading = true;
            
            const { per_page: perPage } = this.calls.pagination;
            try {
                const { data, meta } = await this.getVoipCalls({
                    professionalId: this.professionalId,
                    page,
                    perPage
                });
                const { current_page, last_page } = meta;
                this.calls.data = data;
                this.calls.pagination = {
                    ...this.calls.pagination,
                    current_page,
                    last_page
                };

                this.calls.loading = false;
            } catch (error) {
                window.Toasteo.error(this.$t('voip.calls.errors.api.getCalls'))
                this.$router.replace(this.redirect)
            }
        },
        setPage(page)
        {
            this.$router.replace({
                name: 'professional.inbox.voip.calls.index',
                params: { professional: this.professionalId },
                query: { page }
            }).catch(()=>{});
            this.setCalls(page)
        }
    },
    computed: {
        ...mapGetters(['professionalCanAccessVoipCalls']),
        professionalId()
        {
            return this.$route.params.professional;
        },
        redirect()
        {
            return {
                name: 'professional.inbox.base',
                params: {
                    professional: this.professionalId
                },
            };
        }
    },
    created()
    {
        this.$store.commit('SIDEBAR/SET_SECTION', 'voip-calls');
    },
    // in mounted due to before enter using instance
    async mounted()
    {
        const { page = 1 } = this.$route.query;
        this.setCalls(page);
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
            if(vm.professionalCanAccessVoipCalls) {
                return next(to);
            }
            window.Toasteo.error(vm.$t('voip.calls.errors.access.denied'))
            next(vm.redirect);
        })
    }
}
</script>