window.EXIF = require('exif-js');

import Hermite_class from './vendor/hermite.js';
window.HERMITE = new Hermite_class();

require('./setup');

import axios from 'axios';
axios.interceptors.request.use(function (config) {
    config.headers['Accept-Language'] = window.App.$store.state.locale;
    console.log(config.headers, window.App.$store.state.locale);

    return config;
});