<template>
    <div>
        <div class="mb-3 text-center" v-if="shouldDisplayDate">
            <p class="text-sm font-thin text-gray-600">{{ message.created_at | moment('YYYY-MM-DD HH:mm:ss').format('dddd D MMMM YYYY') }}</p>
        </div>
        <div :class="containerClassName">
            <div class="flex items-end w-2/3">
                <div class="mr-4" v-if="message.received">
                    <div class="w-8 h-8 bg-gray-100 bg-center bg-no-repeat bg-cover rounded-full" :style="`background-image: url('${message.from.avatar}')`" v-if="message.from"></div>
                </div>
                <div class="flex-1" :class="{ 'flex flex-wrap justify-end': message.sent }">
                    <div :class="[className, {'mb-2': message.files.length > 0 || message.images.length > 0}]" v-if="message.text">
                        <p v-html="message.text" v-if="message.text"></p>
                    </div>
                    <div class="block w-full" v-if="message.images.length > 0">
                        <div class="flex flex-wrap -mx-2" :class="{ 'justify-end': message.sent }">
                            <div class="px-2 mb-4" v-for="(image, index) in message.images" :key="`message-image-${index}`">
                                <a :href="image.url.large" target="_blank">
                                    <div class="w-32 h-32 bg-gray-100 bg-center bg-no-repeat bg-cover rounded md:h-24 md:w-24" :style="`background-image: url('${image.url.small}')`"></div>
                                </a>
                            </div>
                        </div>
                        <div class="w-full" :class="{ 'flex justify-end': message.sent, 'mb-2': message.files.length > 0 }">
                            <span class="text-xs">Cliquez sur l'image pour l'agrandir</span>
                        </div>
                    </div>
                    <div class="block w-full" v-if="message.files.length > 0">
                        <div v-for="(file, index) in message.files" :key="`message-file-${index}`" class="block w-full" :class="{ 'flex justify-end': message.sent }">
                            <a :href="file.url" target="_blank" class="flex items-center space-x-2 account__conversation__files__file">
                                <i class="far fa-file-alt"></i>
                                <p v-text="file.name"></p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message', 'shouldDisplayDate'],
    computed: {
        containerClassName() {
            if ( this.message.sent ) {
                return 'flex w-full justify-end mb-6';
            }
            return 'flex w-full justify-start mb-6';
        },
        className() {
            if ( this.message.sent ) {
                return 'break-words-gracefully inline-block p-4 rounded-chat-bubble bg-chat-bubble-sent text-white font-thin text-sm';
            }
            return 'break-words-gracefully inline-block p-4 rounded-chat-bubble bg-chat-bubble-received text-gray-700 font-thin text-sm';
        }
    }
}
</script>
