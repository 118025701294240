import Edit from '~base/components/Professionals/Inbox/Settings/Edit';
import AutomaticAnswersIndex from '~base/components/Professionals/Inbox/Settings/AutomaticAnswers/Index';
import AutomaticAnswersCreate from '~base/components/Professionals/Inbox/Settings/AutomaticAnswers/Create';
import AutomaticAnswersEdit from '~base/components/Professionals/Inbox/Settings/AutomaticAnswers/Edit';
import QuickAnswersIndex from '~base/components/Professionals/Inbox/Settings/QuickAnswers/Index';
import QuickAnswersCreate from '~base/components/Professionals/Inbox/Settings/QuickAnswers/Create';
import QuickAnswersEdit from '~base/components/Professionals/Inbox/Settings/QuickAnswers/Edit';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'automatic-answers',
        component: AutomaticAnswersIndex,
        name: 'professional.inbox.settings.automatic-answers.index',
        meta: {
            title: '{professionalname} - Automatic Answers'
        }
    }).protected().get(),

    generator.create({
        path: 'automatic-answers/create',
        component: AutomaticAnswersCreate,
        name: 'professional.inbox.settings.automatic-answers.create',
        meta: {
            title: '{professionalname} - Automatic Answers - Create'
        }
    }).protected().get(),

    generator.create({
        path: 'automatic-answers/:answer',
        component: AutomaticAnswersEdit,
        name: 'professional.inbox.settings.automatic-answers.edit',
        meta: {
            title: '{professionalname} - Edit Automatic Answer'
        }
    }).protected().get(),

    generator.create({
        path: 'quick-answers',
        component: QuickAnswersIndex,
        name: 'professional.inbox.settings.quick-answers.index',
        meta: {
            title: '{professionalname} - Quick Answers'
        }
    }).protected().get(),

    generator.create({
        path: 'quick-answers/create',
        component: QuickAnswersCreate,
        name: 'professional.inbox.settings.quick-answers.create',
        meta: {
            title: '{professionalname} - Quick Answers - Create'
        }
    }).protected().get(),

    generator.create({
        path: 'quick-answers/:answer',
        component: QuickAnswersEdit,
        name: 'professional.inbox.settings.quick-answers.edit',
        meta: {
            title: '{professionalname} - Edit Quick Answer'
        }
    }).protected().get()
]