<template>
    <div class="professional-base">
        <layout>
            <template slot="content">
                <v-spa-panel panel-height="auto" :loading="loading" v-if="loading"></v-spa-panel>
                <div class="h-full router-base" v-if="! loading && $store.state.professional && $store.state.professional.id">
                    <router-view :key="'professional-' + $store.state.professional.id + '-' + $store.state.professional.updated_at"></router-view>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>

    import {mapActions} from 'vuex';
    import Layout from '~base/components/Layout';

    export default {
        components: {
            'layout': Layout,
        },
        data() {
            return {
                loading: true,
            }
        },
        methods: {
            getProfessional(id)
            {
                return new Promise((resolve, reject) => {
                    this.loading = true;

                    this.$store.dispatch('getProfessional', id)
                        .then((professional) => {
                            this.loading = false;
                            this.$store.dispatch('setProfessional', professional);

                            resolve();
                        })
                        .catch(error => {
                            console.log(error);
                            window.Toasteo.error('Professional #' + id + ' not found.');
                            // this.$router.push({name: 'professionals.index'});
                        });
                });
            }
        },
        created() {
            this.getProfessional(this.$route.params.professional);
        },
        beforeRouteUpdate (to, from , next) {
            this.$store.state.layout.container = true;
            if ( typeof window.Intercom === 'function' ) {
                window.Intercom('update', {
                    "hide_default_launcher": ! this.$store.state.layout.intercom
                });
            }
            next();
        } 
    }
</script>
