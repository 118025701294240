<template>
    <div>
        <div class="relative p-16 bg-white shadow" v-if="loading">
            <v-spa-loader></v-spa-loader>
        </div>
        <div class="flex flex-wrap -mx-4" v-else>
            <h1 class="mb-4 text-xl font-thin text-gray-700">{{ model.title }}</h1>
            <div>
                <router-link :to="{name: 'professional.visibility.projects.edit', params: {professional: $store.state.professional.id, project: model.id}}" class="px-4 py-2 text-white bg-blue-600 rounded">Edit</router-link>
                <span class="px-4 py-2 text-white bg-red-600 rounded" @click.prevent="deleteProject">DELETE</span>
            </div>
        </div>

    </div>
</template>

<script>
    import mixin from '~spa/components/_Table/show-mixin.js';

    export default {
        mixins: [mixin],

        methods: {
            deleteProject()
            {
                this.$store.dispatch('deleteProject', {professionalId: this.$store.state.professional.id, projectId: this.model.id})
                    .then((response) => {
                        window.Toasteo.success('Project deleted');
                        this.$router.push({name: 'professional.visibility.projects.index', params: {professional: this.$store.state.professional.id}});
                    });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-projects');

            this.$store.dispatch('getProject', {professionalId: this.$store.state.professional.id, projectId: this.$route.params.project})
                .then(project => {
                    this.model = project;
                    this.loading = false;
                });
        }
    }
</script>
