import Index from '~base/components/Professionals/Inbox/Requests/Index';
import Show from '~base/components/Professionals/Inbox/Requests/Show';
import Print from '~base/components/Professionals/Inbox/Requests/Print';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

export const routes = [
    generator.create({
        path: 'requests',
        component: Index,
        name: 'professional.inbox.requests.index',
        meta: {
            title: '{professionalname} - requests'
        }
    }).protected().get(),

    generator.create({
        path: 'requests/:request',
        component: Show,
        name: 'professional.inbox.requests.show',
        meta: {
            title: '{professionalname} - Request',
            layout: {
                intercom: false
            } 
        }
    }).protected().get(),

    generator.create({
        path: 'requests/:request/print',
        component: Print,
        name: 'professional.inbox.requests.print',
        meta: {
            title: '{professionalname} - Request',
            layout: {
                sidebar: false,
                container: false
            }
        }
    }).protected().get(),

]