<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('visibility.facebook_pages.title') }}</template>

        <div slot="title-button">
            <v-spa-loading-button v-if="facebook_pages.length > 0" @clicked="$modal.show('facebook-pages-publish');">
                {{ $t('visibility.facebook_pages.create') }}
            </v-spa-loading-button>
        </div>

        <template v-slot:content>
            <v-spa-panel :loading="loading" v-if="loading"></v-spa-panel>

            <template v-else>
                <v-spa-panel panel-class="p-12" v-if="facebook_pages.length == 0">
                    <div class="w-[400px] mx-auto mb-12">
                        <v-spa-lottie-empty-2></v-spa-lottie-empty-2>
                    </div>
                    <p class="mb-6 text-lg font-thin text-center text-gray-700">{{ $t('visibility.facebook_pages.empty.text') }}</p>
                    <div class="flex justify-center w-full">
                        <v-spa-loading-button :loading="linking" @clicked="loginWithFacebook">
                            {{ $t('visibility.facebook_pages.empty.button') }}
                        </v-spa-loading-button>
                    </div>
                </v-spa-panel>
                <div v-else>
                    <v-spa-panel panel-class="py-2">
                        <div class="relative flex items-center justify-start w-full px-4 py-2" :class="{'border-b border-gray-100 mb-2': index + 1 !== facebook_pages.length}" v-for="(page, index) in facebook_pages" :key="page.id">
                            <v-spa-loader v-if="deleting.includes(page.id)"></v-spa-loader>
                            <div class="w-12 h-12 bg-center bg-cover rounded-full" :style="{'background-image': 'url(' + page.image + ')'}"></div>
                            <div class="ml-4">
                                <span class="block mb-1 text-gray-700">{{ page.name }}</span>
                            </div>
                            <div class="flex-grow"></div>
                            <span class="font-thin text-gray-400 cursor-pointer hover:text-gray-600" @click.prevent="deletePage(page)">x</span>
                        </div>
                    </v-spa-panel>

                    <portal to="modals">
                        <v-spa-right-panel-modal name="facebook-pages-publish" right-panel-width="550px" right-panel-width-xl="550px">
                            <feed-create :pages="facebook_pages" />
                        </v-spa-right-panel-modal>
                    </portal>
                </div>
                <div class="flex justify-center mt-6" v-if="facebook_pages.length > 0">
                    <v-spa-loading-button :loading="linking" @clicked="loginWithFacebook">
                        {{ $t('visibility.facebook_pages.link_new_pages_button') }}
                    </v-spa-loading-button>
                </div>
            </template>
        </template>
    </v-spa-layout-page>
</template>

<script>

    import Vue from 'vue';
    require('chart.js');
    require('hchs-vue-charts');
    Vue.use(VueCharts);

    import FeedCreate from "~base/components/Professionals/Visibility/FacebookPages/Feed/Create";
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';


    export default {
        components: {
            FeedCreate,
            'tmp-spa-page': Page
        },

        data() {
            return {
                loading: true,
                linking: false,
                deleting: [],
                facebook_pages: [],
                form: {
                    message: '',
                    images: []
                }
            }
        },

        methods: {
            loginWithFacebook()
            {
                let vm = this;
                this.linking = true;
                FB.login((response) => {
                    FB.api('/me/accounts', (apiResponse) => {
                        if ( ! apiResponse.data || apiResponse.data.length == 0 ) {
                            window.Toasteo.warning(
                                this.$t('visibility.facebook_pages.no_page_found')
                            );
                            vm.linking = false;
                            return;
                        }

                        vm.addNewPages(apiResponse.data)
                            .then(() => {
                                window.Toasteo.warning(
                                    this.$t('visibility.facebook_pages.success')
                                );
                                vm.linking = false;
                            });
                    });
                }, {scope: 'pages_show_list,pages_messaging,pages_manage_posts,pages_read_engagement'});
            },

            addNewPages(pages)
            {
                return new Promise((resolve) => {
                    Promise.all(
                        pages
                            .filter(page => ! this.pageExists(page))
                            .map(page => this.addNewPage(page))
                    ).then(() => resolve());
                });
            },

            addNewPage(page)
            {
                let vm = this;

                return new Promise((resolve) => {
                    FB.api("/" + page.id + "/picture?redirect=false", (response) => {

                        let data = {
                            page_id: page.id,
                            access_token: page.access_token,
                            name: page.name,
                            is_selected: false
                        };

                        if ( response && ! response.error ) {
                            data.image = response.data.url;
                        }

                        vm.storePage(data)
                            .then((page) => {
                                vm.addPage(page);
                                resolve()
                            });
                    });
                });
            },

            pageExists(page)
            {
                if ( ! this.facebook_pages ) {
                    return false;
                }

                return this.facebook_pages.some(p => p.page_id == page.id);
            },

            addPage(page)
            {
                if ( ! this.facebook_pages ) {
                    this.facebook_pages = [];
                }

                this.facebook_pages.push(page);
            },

            storePage(page)
            {
                return new Promise((resolve) => {
                    this.$store.dispatch('storeFacebookPage', {professionalId: this.$store.state.professional.id, page: page})
                        .then((response) => resolve(response.data.page));
                });
            },

            deletePage(page)
            {
                this.deleting.push(page.id);
                this.$store.dispatch('deleteFacebookPage', {professionalId: this.$store.state.professional.id, pageId: page.id})
                    .then(() => {
                        this.facebook_pages = this.facebook_pages.filter(p => p.id !== page.id);
                        this.deleting = this.deleting.filter(id => id !== page.id);
                        window.Toasteo.success('Page deleted.');
                    });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-facebook-pages');

            this.$store.dispatch('getFacebookPages', {professionalId: this.$store.state.professional.id})
                .then(pages => {
                    this.facebook_pages = pages;
                    this.loading = false;
                });
        }
    }
</script>
